import { TabsContent } from '@/components/ui/tabs';
import { ScriptDetailsTab } from '../editor/ScriptDetailsRoot';
import { Stats } from './Stats';
import { Messages } from './Messages';
import { ScrollArea } from '@/components/ui/scroll-area';
import { CodeSample } from '@/components/ui/code-sample';
import { POLLING_SAMPLE } from '@/constants/code-sample';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Loader } from '@/utils/loader';
import { GetScriptHistory } from '@gql';

export function Activity() {
  const { scriptId } = useParams<{ scriptId: ScriptId }>();

  const storeHistory = Loader.query<GetScriptHistory>(
    gql`
      query GetScriptHistory($id: ScriptId!) {
        script(id: $id) {
          storeHistory
        }
      }
    `,
    {
      variables: { id: scriptId },
    },
  ).map(x => x.script.storeHistory);

  const isKeepHistoryEnabled = storeHistory.match.notOk(() => null).ok(history => !!history);
  
  return (
    <TabsContent value={ScriptDetailsTab.Activity} className="flex flex-col gap-6 pt-6 overflow-hidden">
      <ScrollArea>
        <Stats />
        <Messages />
        <CodeSample
          title="Realtime reliable polling"
          className="my-4"
          docUrl="https://docs.bloomr.stream/DataStreaming/polling"
          sampleWithSdk={POLLING_SAMPLE}
          scriptId={scriptId}
          warning={!isKeepHistoryEnabled 
            ? '"Keep history" must be enabled for this sample to work' 
            : undefined
          }
        />
      </ScrollArea>
    </TabsContent>
  );
}
