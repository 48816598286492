import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import { AuthenticateWeb3, AuthenticateWeb3Variables, GetNonce, GetNonceVariables } from '@gql';
import { setAuthToken, setCurrentAddress } from '../apollo';
import { Button } from '@/components/ui/button';
import { GoogleIcon, MetaMaskIcon } from '@ui-kit/Icons';
import { Badge } from '@/components/ui/badge';
import { GitHubLogoIcon } from '@radix-ui/react-icons';
import { toast } from 'sonner';

declare let ethereum: any;

export function Login() {
  const client = useApolloClient();
  const isDisabled = typeof ethereum !== 'object' || typeof ethereum.request !== 'function';

  return (
    <div className="flex overflow-clip h-full w-full">
      <div className="flex flex-col justify-between p-5 bg-[url('/src/assets/login.png')] w-[667px]">
        <div className="flex flex-col gap-2 w-fit">
          <span className="font-bold leading-4">bloomr</span>
          <Badge className="rounded-full w-fit">Beta</Badge>
        </div>
      </div>
      <div className="flex items-center justify-center flex-col w-full">
        <div className="flex flex-col gap-5 items-center w-min">
          <div className="pb-4">
            <h3>Login</h3>
          </div>
          <Button
            onClick={() => loginMetamask(client)}
            disabled={isDisabled}
            variant="secondary"
            className="flex items-center gap-2 w-[345px]"
            size="lg"
          >
            <MetaMaskIcon className="w-4 h-4" />
            <span className="text-base font-medium">MetaMask</span>
          </Button>
          <Button
            onClick={() => loginMetamask(client)}
            disabled
            variant="secondary"
            className="flex items-center gap-2 w-[345px]"
            size="lg"
          >
            <GitHubLogoIcon className="w-4 h-4" />
            <span className="text-base font-medium">Github</span>
          </Button>
          <Button
            onClick={() => loginMetamask(client)}
            disabled
            variant="secondary"
            className="flex items-center gap-2 w-[345px]"
            size="lg"
          >
            <GoogleIcon className="w-4 h-4" />
            <span className="text-base font-medium">Google</span>
          </Button>
          <div className="border-t py-4 text-base text-muted-foreground text-center">
            By logging in, you agree to our{' '}
            <a href="https://bloomr-docs.pages.dev/" target="_blank" className="underline">
              Terms of services
            </a>{' '}
            and{' '}
            <a href="https://bloomr-docs.pages.dev/" target="_blank" className="underline">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

async function loginMetamask(client: ApolloClient<any>) {
  console.log('Metamask login...');
  try {
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });

    const { data } = await client.query<GetNonce, GetNonceVariables>({
      query: gql`
        query GetNonce($address: HexString!) {
          getSignMessage(address: $address)
        }
      `,
      variables: {
        address: accounts[0],
      },
    });

    const sign = await ethereum.request({
      method: 'personal_sign',
      params: [data.getSignMessage, accounts[0]],
    });
    console.log('...signed message');

    const {
      data: { authenticate },
    } = await client.query<AuthenticateWeb3, AuthenticateWeb3Variables>({
      query: gql`
        query AuthenticateWeb3($address: HexString!, $signature: HexString!) {
          authenticate(address: $address, signature: $signature)
        }
      `,
      variables: {
        address: accounts[0],
        signature: sign,
      },
    });

    console.log('...authenticated !');
    setAuthToken(authenticate);
    setCurrentAddress(accounts[0]);
  } catch (error) {
    console.warn('Login failed', error);
    toast.error('Failed to login');
  }
}
