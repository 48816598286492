import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { ScriptState } from './useScript';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { ScriptTester } from './tester/ScriptTester';
import { cn } from '@/lib/utils';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { RealtimeList } from './editor/RealtimeList';

type SimulateProps = {
  script: ScriptState;
  isDraft: boolean;
  isOpen: boolean;
};

export function Simulate({ script, isDraft, isOpen }: SimulateProps) {
  return (
    <AccordionItem value="simulate" className={cn('flex flex-col overflow-auto', !isOpen && 'flex-shrink-0')}>
      <AccordionTrigger className="text-sm leading-[14px] font-medium hover:no-underline">{isDraft ? 'Simulate draft' : 'Simulate deployed script'}</AccordionTrigger>
      <AccordionContent asChild className="flex h-full w-full">
        <ScrollArea className="w-full">
          <div className="flex flex-col">
            <Tabs defaultValue="specific-block">
              <TabsList className="w-full bg-secondary">
                <TabsTrigger
                  value="specific-block"
                  className="w-full data-[state=active]:bg-foreground data-[state=active]:text-background"
                >
                  On specific block
                </TabsTrigger>
                <TabsTrigger value="real-time" className="w-full data-[state=active]:bg-foreground data-[state=active]:text-background">
                  On real time
                </TabsTrigger>
              </TabsList>
              <TabsContent value="specific-block">
                <ScriptTester script={script} />
              </TabsContent>
              <TabsContent value="real-time">
                {script.scriptId && (<RealtimeList id={script.scriptId} draft={isDraft} hasUnsavedChanges={script.hasChanged} />)}
              </TabsContent>
            </Tabs>
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </AccordionContent>
    </AccordionItem>
  );
}
