import { DashboardCard } from '@/components/ui/dashboard-card';
import { Loadable, Loader, by, notNil, showLargeNumber, showUsd, toLookup } from '@utils';
import { BillingChart, ChartItem } from './Chart';
import { LoadGlobalBillingGraph, LoadScriptBillingGraph } from '@gql';
import hexToHsl, { HSL } from 'hex-to-hsl';
import { ReactNode } from 'react';
import { CircleDollarSignIcon, CoinsIcon } from 'lucide-react';
import { Skeleton } from '@/components/ui/skeleton';
import { ErrorAlert } from '@/components/ui/alert';

export type BillingRecord = {
  [key: string]: BillingItem;
} & {
  date: string;
};

interface BillingItem {
  n: number;
  v: number;
  cu: number;
  $: number;
}

// https://color.adobe.com/fr/create/color-wheel
const knownCategories: Record<string, HSL> = {
  Execution: hexToHsl('#D99262'),
  'Data transfers': hexToHsl('#BED962'),
  'Address lists': hexToHsl('#62D1D9'),
  'Script databases': hexToHsl('#B162D9'),
  Misc: hexToHsl('#678284'),
};

const parseGraph = (usd: boolean, data: LoadScriptBillingGraph | LoadGlobalBillingGraph) => {
  const rawGraph =
    'script' in data
      ? (data.script.billingGraph as unknown as BillingRecord[])
      : (data.billingGraph as unknown as BillingRecord[]);
  const byId = by(data.billingItems, x => x.id);
  let totalCu = 0;
  let total$ = 0;
  const items = new Map<string, ChartItem>();

  // determine color palette
  const byCat = toLookup(data.billingItems, x => x.category);
  const colors = byCat.flatMap(([cat, items]) =>
    items.map((x, i) => {
      const [h, s, l] = knownCategories[cat] ?? knownCategories['Misc'];
      // have a small variation of catColor, based on its index
      const variation = 0.5 - i / items.length;
      const adjustedLightness = l + 50 * variation;
      return { id: x.id, color: `hsl(${h}, ${s}%, ${adjustedLightness}%)` };
    }),
  );
  const colorsById = by(
    colors,
    x => x.id,
    x => x.color,
  );

  const graph = rawGraph.map(x => ({
    date: x.date,
    ...Object.fromEntries(
      notNil(
        Object.entries(x).map(([key, value]) => {
          if (key === 'date' || typeof value === 'string') {
            return null;
          }
          const item = byId.get(key);
          let cat: string;
          let name: string;
          let id = '-';
          if (!item) {
            cat = 'Unknown';
            name = key;
          } else {
            cat = item.category;
            name = item.name;
            id = item.id;
          }
          totalCu += value.cu;
          total$ += value.$;
          if (!items.has(name)) {
            items.set(name, {
              dataKey: name,
              name: name,
              color: colorsById.get(id) ?? 'red',
            });
          }
          return [name, usd ? value.$ : value.cu];
        }),
      ),
    ),
  }));

  return {
    graph,
    totalCu,
    total$,
    items: Array.from(items.values()),
  };
};

export function BillingGraph({
  data,
  usd = false,
  info,
}: {
  usd?: boolean;
  data: Loadable<LoadScriptBillingGraph | LoadGlobalBillingGraph>;
  info?: ReactNode;
}) {
  const stats = Loader.useWrap(data).map([usd], v => parseGraph(usd, v));

  return (
    <div className="flex gap-6 h-full">
      {stats.match
        .loadingOrSkipped(() =>
          <>
            <div className="flex flex-col h-[442px] justify-between gap-6">
              <Skeleton className="w-[325px] h-full" />
              <Skeleton className="w-[325px] h-full" />
              <Skeleton className="w-[325px] h-full" />
            </div>
            <Skeleton className="w-full" />
          </>
        )
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(({ total$, totalCu, graph, items }) => (
          <>
            <div className="flex flex-col h-[442px] justify-between">
              <DashboardCard
                title="Credits used"
                value={showLargeNumber(totalCu)}
                icon={<CoinsIcon className="w-4 h-4" />}
                sub="Total CU used"
              />
              <DashboardCard
                title="Estimated Cost"
                value={showUsd(total$)}
                icon={<CircleDollarSignIcon className="w-4 h-4" />}
                sub="Equivalent $ spent"
              />
              <div className="h-[124px]" />
            </div>
            <BillingChart data={graph} items={items} usd={usd} info={info} />
          </>
        ))}
    </div>
  );
}
