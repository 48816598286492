import { Button } from '@/components/ui/button';
import { gql, useApolloClient } from '@apollo/client';
import { ScriptList, ScriptList_scripts } from '@gql';
import { Loader, fetchApolloAsync } from '@utils';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ScriptStatusBadge } from './ScriptStatusBadge';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { FormField } from '@/components/ui/form';
import { useScript } from './useScript';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Edit3Icon, MoreHorizontalIcon, PlusSquareIcon, SaveIcon, Trash2Icon } from 'lucide-react';
import { Line, LineChart, ResponsiveContainer } from 'recharts';
import { ScriptDetailsTab } from './editor/ScriptDetailsRoot';
import { Pagination, PaginationContent, PaginationItem, PaginationNext, PaginationPrevious } from '@/components/ui/pagination';
import { TableLoader } from '@/components/ui/skeleton';
import { ErrorAlert, InfoAlert } from '@/components/ui/alert';
import { toast } from 'sonner';

const MAX_CNT = 10;

export function ScriptsList() {
  const [skip, setSkip] = useState(0);
  const [reloader, setReloader] = useState(0);
  const [newScriptName, setNewScriptName] = useState('');
  const [isCreateNewModalOpen, setIsCreateNewModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchValue = searchParams.get('search');

  const pageDiff = (i: number) => setSkip(x => x + i * MAX_CNT);

  const scripts = Loader
    .useWrap([searchValue, reloader] as const)
    .map(([searchValue]) => searchValue)
    .debounce(500)
    .query<ScriptList>(
      [reloader, skip],
      gql`
      query ScriptList($skip: Int, $take: Int!, $search: String) {
        scripts(skip: $skip, take: $take, search: $search) {
          id
          name
          status
          stats
        }
      }
    `,
      search => ({ skip, take: MAX_CNT, search }),
    ).map(x => x.scripts);

  const { onSave } = useScript();

  const handleSaveNewScript = async () => {
    onSave({ name: newScriptName });
    setNewScriptName('');
  }

  const handleSearchValueChange = (value: string) => {
    setSearchParams({ search: value }, { replace: true });
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex gap-4 pr-2 pl-4 pt-4 pb-8 items-center">
        <div className="flex-1 py-1">
          <h4>Scripts</h4>
        </div>
        <Input
          className="h-10 bg-secondary w-min"
          placeholder='Search...'
          value={searchValue as string}
          onChange={e => handleSearchValueChange(e.target.value)}
        />
        <Dialog open={isCreateNewModalOpen} onOpenChange={setIsCreateNewModalOpen}>
          <DialogTrigger asChild>
            <Button size="lg" className="flex items-center gap-2">
              <PlusSquareIcon className="w-4 h-4" />
              <span>Create new</span>
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>New script</DialogTitle>
              <DialogDescription>
                Introduce your script by giving it a name, a description and any related protocols. This will enable you
                to find it easily.
              </DialogDescription>
            </DialogHeader>
            <div className="flex flex-col gap-2">
              <FormField label="Name" className="pb-6">
                <Input
                  className="h-10 bg-secondary border-none rounded-xl"
                  value={newScriptName}
                  onChange={e => setNewScriptName(e.target.value)}
                  placeholder="Name of your script"
                />
              </FormField>
              {/* hidden for now */}
              {/* <FormField label="Description" className="pb-6">
                <Textarea
                  placeholder="Some words about it"
                  className="bg-secondary border-none rounded-xl min-h-[156px]"
                  disabled
                />
              </FormField>
              <FormField label="Tags" className="pb-6">
                <Input className="h-10 bg-secondary border-none rounded-xl" disabled />
              </FormField> */}
            </div>
            <DialogFooter className="pt-8">
              <Button size="lg" variant="secondary" onClick={() => setIsCreateNewModalOpen(false)}>
                Cancel
              </Button>
              <Button disabled={!newScriptName} size="lg" className="flex items-center gap-2" type="submit" onClick={handleSaveNewScript}>
                <SaveIcon className="w-4 h-4" />
                Save
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      {scripts.match
        .loadingOrSkipped(() => <TableLoader />)
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(scs => (
          <ScrollArea>
            <LoadedScriptList scripts={scs} onReload={() => setReloader(x => x + 1)} searchValue={searchValue} />
            {scs.length > 0 && <Pagination className="py-5">
              <PaginationContent>
                <PaginationItem data-state={!skip && 'disabled'} className="cursor-pointer" onClick={() => pageDiff(-1)}>
                  <PaginationPrevious />
                </PaginationItem>
                <PaginationItem data-state={scs.length < MAX_CNT && 'disabled'} className="cursor-pointer" onClick={() => pageDiff(1)}>
                  <PaginationNext />
                </PaginationItem>
              </PaginationContent>
            </Pagination>}
          </ScrollArea>
        ))}
    </div>
  );
}

function LoadedScriptList({ scripts, onReload, searchValue }: { scripts: readonly ScriptList_scripts[]; onReload?: () => void; searchValue: string | null}) {

  if (!scripts.length) {
    return <InfoAlert message={searchValue ? `No script for "${searchValue}"` : "No script yet"} />;
  }

  return (
    <Table className="border-separate border-spacing-y-2">
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Success today</TableHead>
          <TableHead>Error today</TableHead>
          <TableHead>Msg Today</TableHead>
          <TableHead>24H chart</TableHead>
          <TableHead />
        </TableRow>
      </TableHeader>
      <TableBody>
        {scripts.map(script => (
          <ScriptItem key={script.id} script={script} onReload={onReload} />
        ))}
      </TableBody>
    </Table>
  );
}

type ChartSummaryProps = {
  chart: unknown[];
}

export function ChartSummary({ chart }: ChartSummaryProps) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={chart} className="!cursor-pointer">
        <Line type="monotone" dataKey="emitted" key="date" stroke="var(--foreground)" strokeWidth="2" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  )
}

const parseStats = (stats: any[]) => {
  let successToday = 0;
  let errorsToday = 0;
  let emittedToday = 0;
  for (const { success, errors, emitted } of stats) {
    successToday += success;
    errorsToday += errors;
    emittedToday += emitted;
  }
  return {
    emittedToday,
    errorsToday,
    successToday,
    graph: stats,
  };
};

type ScriptItemProps = {
  script: ScriptList_scripts;
  onReload?: VoidFunction;
}

function ScriptItem({ script, onReload }: ScriptItemProps) {
  const navigateTo = useNavigate();
  const apollo = useApolloClient();

  const { emittedToday, errorsToday, graph, successToday } = parseStats(script.stats as any)

  const deleteScript = async (id: ScriptId) => {
    try {
      await fetchApolloAsync(
        apollo,
        gql`
          mutation DeleteScript($id: ScriptId!) {
            deleteScript(id: $id)
          }
        `,
        { id },
      );
      onReload?.();
      toast.success('Script deleted !');
    } catch (e) {
      console.error('delete script failed', e);
      toast.error('Failed to delete script');
    }
  };

  return (
    <TableRow data-type="primary" key={script.id} onClick={() => navigateTo(`./${script.id}`)}>
      <TableCell className="font-bold">{script.name}</TableCell>
      <TableCell>
        <ScriptStatusBadge status={script.status} />
      </TableCell>
      <TableCell>{successToday}</TableCell>
      <TableCell>{errorsToday}</TableCell>
      <TableCell>{emittedToday}</TableCell>
      <TableCell className='max-w-10'>
        <div className='h-12'>
          <ChartSummary chart={graph as any} />
        </div>
      </TableCell>
      <TableCell className="text-right pr-5">
        <AlertDialog>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon">
                <MoreHorizontalIcon className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent onClick={e => e.stopPropagation()}>
              <DropdownMenuItem className="gap-2.5" onClick={() => navigateTo(`./${script.id}?tab=${ScriptDetailsTab.Code}`)}>
                <Edit3Icon className="w-4 h-4" /> Edit
              </DropdownMenuItem>
              <DropdownMenuItem asChild className="gap-2.5 w-full">
                <AlertDialogTrigger>
                  <Trash2Icon className="w-4 h-4" />
                  Delete
                </AlertDialogTrigger>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <AlertDialogContent onClick={e => e.stopPropagation()}>
            <AlertDialogHeader>
              <AlertDialogTitle>Delete confirmation</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone. This will permanently delete your script and remove your data from
                our servers.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={() => deleteScript(script.id)}>Confirm</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </TableCell>
    </TableRow>
  )
}
