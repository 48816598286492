import { ErrorAlert } from '@/components/ui/alert';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { DialogHeader, DialogFooter, Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { FormField } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MiniPayload } from '@/components/ui/mini-payload';
import { ScrollArea } from '@/components/ui/scroll-area';
import { TableLoader } from '@/components/ui/skeleton';
import { TableHeader, TableRow, TableHead, TableBody, TableCell, Table } from '@/components/ui/table';
import { gql, useApolloClient } from '@apollo/client';
import { LoadScriptDb } from '@gql';
import { Loader } from '@utils';
import { PlusSquareIcon, SaveIcon, Trash2Icon } from 'lucide-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

export function DatabaseDetails() {
  const { name } = useParams();
  const apollo = useApolloClient();

  const [isNewEntryModalOpen, setIsNewEntryModalOpen] = useState(false);

  const [newEntryKey, setNewEntryKey] = useState('');
  const [newEntryValue, setNewEntryValue] = useState('');

  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const refresh = () => setRefreshTrigger(prev => prev + 1);

  const details = Loader.query<LoadScriptDb>(
    gql`
      query LoadScriptDb($name: String!) {
        scriptDb(name: $name) {
          name
          entries {
            key
            value
          }
        }
      }
    `,
    {
      variables: {
        name,
      },
      refetchWhenChanges: [refreshTrigger],
    },
  ).map(data => data.scriptDb);

  const addNewEntry = async () => {
    try {
      await apollo.mutate({
        mutation: gql`
          mutation AddNewDatabaseEntry($db: String!, $key: String!, $valueJson: JSON) {
            setScriptDbEntry(db: $db, key: $key, valueJson: $valueJson)
          }
        `,
        variables: {
          db: name,
          key: newEntryKey,
          valueJson: newEntryValue,
        },
      });
      setNewEntryKey('');
      setNewEntryValue('');
      setIsNewEntryModalOpen(false);
      refresh();
    } catch (e) {
      console.error('Failed to add entry', e);
      toast.error('failed to add entry');
    }
  };

  const editEntry = async (key: string, newValue: string) => {
    try {
      await apollo.mutate({
        mutation: gql`
          mutation EditDatabaseEntry($db: String!, $key: String!, $valueJson: JSON) {
            setScriptDbEntry(db: $db, key: $key, valueJson: $valueJson)
          }
        `,
        variables: {
          db: name,
          key: key,
          valueJson: newValue,
        },
      });
      refresh();
    } catch (e) {
      console.error('Failed to edit entry', e);
      toast.error('failed to edit entry');
    }
  };

  const deleteEntry = async (key: string) => {
    try {
      await apollo.mutate({
        mutation: gql`
          mutation DeleteDatabaseEntry($db: String!, $key: String!) {
            setScriptDbEntry(db: $db, key: $key)
          }
        `,
        variables: {
          db: name,
          key: key,
        },
      });
      refresh();
    } catch (e) {
      console.error('Failed to delete entry', e);
      toast.error('failed to delete entry');
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex gap-4 pr-2 pl-4 pt-4 pb-8 items-center">
        <div className="flex-1 py-1">
          <h4>{details.match.notOk(() => '').ok(_details => _details.name)}</h4>
        </div>
        <Dialog open={isNewEntryModalOpen} onOpenChange={setIsNewEntryModalOpen}>
          <DialogTrigger asChild>
            <Button size="lg" className="flex items-center gap-2">
              <PlusSquareIcon className="w-4 h-4" />
              <span>Add entry</span>
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add entry</DialogTitle>
              <DialogDescription>
                This action cannot be undone. This will permanently add an entry in your database.
              </DialogDescription>
            </DialogHeader>
            <div className="flex flex-col gap-2">
              <FormField label="Key" className="pb-6">
                <Input
                  className="h-10 bg-secondary border-none rounded-xl"
                  placeholder="Key"
                  value={newEntryKey}
                  onChange={v => setNewEntryKey(v.target.value)}
                />
              </FormField>
              <FormField label="Value (Json)" className="pb-6">
                <MiniPayload value={newEntryValue} onChange={setNewEntryValue} />
              </FormField>
            </div>
            <DialogFooter className="pt-8">
              <Button size="lg" variant="secondary" onClick={() => setIsNewEntryModalOpen(false)}>
                Cancel
              </Button>
              <Button onClick={addNewEntry} size="lg" type="submit" className="flex items-center gap-2">
                <SaveIcon className="w-4 h-4" />
                Save
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      {details.match
        .loadingOrSkipped(() => <TableLoader />)
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(_details =>
          <ScrollArea>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Key</TableHead>
                  <TableHead>Value</TableHead>
                  <TableHead />
                </TableRow>
              </TableHeader>
              <TableBody>
                {_details.entries.map(entry => (
                  <TableRow key={entry.key} data-type="secondary">
                    <TableCell className="font-normal text-base leading-7">{entry.key}</TableCell>
                    <TableCell className="font-normal text-base leading-7">
                      <MiniPayload
                        value={entry.value}
                        entryKey={entry.key}
                        onChange={(newValue) => editEntry(entry.key, newValue)}
                      />
                    </TableCell>
                    <TableCell className="text-right pr-5">
                      <AlertDialog>
                        <AlertDialogTrigger>
                          <Button variant="secondary" size="icon">
                            <Trash2Icon className="w-4 h-4" />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>Delete confirmation</AlertDialogTitle>
                            <AlertDialogDescription>
                              Are you sure you want to delete this entry?
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={() => deleteEntry(entry.key)}>Confirm</AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
        )}
    </div>
  );
}
