import { useEffect } from 'react';


export function useBeforeUnload(isSafe: boolean) {
  useEffect(() => {
    function beforeUnload(e: BeforeUnloadEvent) {
      if (isSafe) {
        return;
      }
      e.returnValue = '';
      e.preventDefault();
      return '';
    }
  
    window.addEventListener('beforeunload', beforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [isSafe]);
}
