import { Route, Routes } from "react-router-dom";
import { DatabaseList } from "./DatabaseList";
import { DatabaseDetails } from "./DatabaseDetails";

export function Databases() {
  return (
    <Routes>
      <Route path="/" element={<DatabaseList />} />
      <Route path="/:name" element={<DatabaseDetails />} />
    </Routes>
  );
}
