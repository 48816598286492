import { Route, Routes } from 'react-router-dom';
import { ScriptsList } from './ScriptsList';
import { ScriptDetailsRoot } from './editor/ScriptDetailsRoot';

export function Scripts() {
  return (
    <Routes>
      <Route path="/" element={<ScriptsList />} />
      <Route path="/:scriptId" element={<ScriptDetailsRoot />} />
    </Routes>
  );
}
