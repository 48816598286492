import { LogConstraint } from '../filterdef';
import { EventSigEditor } from './SigEditor';
import { Loader } from '../../../../utils/loader';
import { gql } from '@apollo/client';
import { ParseEvent } from '@gql';
import { ParsedEvent } from './parsed-abi-events';
import { EventDataConstraint } from './EventDataConstraint';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { cn } from '@/lib/utils';

export function EventConstraintEditor({
  constraint,
  onChange,
  readOnly,
}: {
  constraint: LogConstraint | nil;
  onChange: (c: LogConstraint | nil) => void;
  readOnly?: boolean;
}) {
  const custom = constraint && !(constraint.logSig in knownEvents);
  const displayedValue = custom ? 'Custom' : constraint?.logSig || '';

  // parse event (if any defined)
  const constraints = Loader.useWrap(constraint?.logSig)
    .debounce(500, true)
    .map(x => x || Loader.skipped)
    .query<ParseEvent>(
      gql`
        query ParseEvent($code: String!) {
          parseEvent(code: $code)
        }
      `,
      x => ({ code: x }),
    )
    .map(x => x.parseEvent as unknown as ParsedEvent);

  const handleEventChange = (event: string) => {
    if (event === 'Custom') {
      onChange({ logSig: '' });
    } else {
      onChange(event ? { logSig: event } : undefined);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <Select onValueChange={handleEventChange} value={displayedValue}>
        <SelectTrigger className={cn("h-10 bg-muted border-none rounded-xl capitalize cursor-pointer", readOnly && 'pointer-events-none')}>
          <SelectValue placeholder="Any event" />
        </SelectTrigger>
        <SelectContent>
          {Object.entries(knownEvents).map(([code, name]) => (
            <SelectItem key={code} value={code}>
              {name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <div className="flex flex-col gap-4 pl-2 border-l">
        {!!custom && (
          <EventSigEditor
            value={constraint?.logSig}
            readOnly={readOnly}
            onChange={v => {
              onChange(v ? { logSig: v } : undefined);
            }}
          />
        )}
        {!!constraint?.logSig &&
          constraints.match
            .skipped(() => null)
            .loading(() => <div className="italic text-accent">Parsing event...</div>)
            .error(() => <div className="italic text-red-600">Your event is not valid</div>)
            .ok(x => <EventDataConstraint readOnly={readOnly} schema={x} constraint={constraint} onChange={onChange} />)}
      </div>
    </div>
  );
}

export const knownEvents: Record<string, string> = {
  Custom: 'Write your own',
  'event Transfer(address indexed from, address indexed to, uint256 value);': 'ERC20 (tokens): Transfer',
  'event Approval(address indexed owner, address indexed spender, uint256 value);': 'ERC20 (tokens): Approval',
  'event Transfer(address indexed from, address indexed to, uint256 indexed tokenId);': 'ERC721 (nfts): Transfer',
  'event Approval(address indexed owner, address indexed approved, uint256 indexed tokenId);':
    'ERC721 (nfts): Approval',
  'event ApprovalForAll(address indexed owner, address indexed operator, bool approved);':
    'ERC721 (nfts): ApprovalForAll',
  'event OwnershipTransferred(address indexed previousOwner, address indexed newOwner);':
    'Ownable: OwnershipTransferred',
  'event Paused(address account);': 'Pausable: Paused',
  'event Unpaused(address account);': 'Pausable: Unpaused',
  'event Deposited(address indexed user, uint256 amount);': 'Deposited',
  'event Withdrawn(address indexed user, uint256 amount);': 'Withdrawn',
};
