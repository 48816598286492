import { Route, Routes } from 'react-router-dom';
import { AddressListsList } from './AddressListsList';
import { AddressListDetails } from './AddressListDetails';

export function AddressLists() {
  return (
    <Routes>
      <Route path="/" element={<AddressListsList />} />
      <Route path="/:list" element={<AddressListDetails />} />
    </Routes>
  );
}
