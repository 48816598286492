import { LoadScriptName, LoadScriptStatus } from '@gql';
import { Loader, useRefresherCounter } from '@utils';
import { useParams } from 'react-router-dom';
import { ScriptStatusBadge } from './ScriptStatusBadge';
import { gql, useApolloClient } from '@apollo/client';
import { MouseEvent, useState } from 'react';
import { Input } from '@/components/ui/input';
import { CheckIcon, Edit3Icon } from 'lucide-react';
import { Skeleton } from '@/components/ui/skeleton';
import { ErrorAlert } from '@/components/ui/alert';
import { toast } from 'sonner';
import { WarningIcon } from '@ui-kit/Icons';
import { useNetworkStatus } from '@/hooks/useNetworkStatus';

export function ScriptDetailsHeader() {
  const scriptId = useParams().scriptId as ScriptId;
  const { isOnline } = useNetworkStatus();


  const autoRefresh = useRefresherCounter(5000);

  const scriptName = Loader.query<LoadScriptName>(
    gql`
      query LoadScriptName($id: ScriptId!) {
        script(id: $id) {
          name
        }
      }
    `,
    {
      variables: { id: scriptId },
    },
  ).map(x => x.script);

  const scriptStatus = Loader.query<LoadScriptStatus>(
    gql`
      query LoadScriptStatus($id: ScriptId!) {
        script(id: $id) {
          status
        }
      }
    `,
    {
      variables: { id: scriptId },
      refetchWhenChanges: [autoRefresh],
    },
  ).map(x => x.script);

  return (
    <div className="p-4 flex-1">
      <div className="py-1 gap-[18px] flex items-center">
        {scriptName.match
          .loadingOrSkipped(() => <Skeleton className="h-7 w-20" />)
          .error((e) => <ErrorAlert name={e.name} message={e.message} />)
          .ok(({ name }) => (
            <ScriptName name={name} />
          ))}
        {scriptStatus.noFlickering().match
          .loadingOrSkipped(() => <Skeleton className="h-8 w-24" />)
          .error((e) =>
            <div className="flex gap-2 items-center text-destructive">
              <WarningIcon />
              {isOnline ? e.message : 'You\'re currently offline. Please check your internet connection.'}
            </div>)
          .ok(({ status }) => (
            <ScriptStatusBadge status={status} />
          ))}
      </div>
    </div>
  );
}

type ScriptNameProps = {
  name: string | null;
};

function ScriptName({ name: _name }: ScriptNameProps) {
  const apollo = useApolloClient();
  const scriptId = useParams().scriptId as ScriptId;

  const [isEditNameMode, setIsEditNameMode] = useState(false);
  const [name, setName] = useState(_name ?? '');
  const [displayName, setDisplayName] = useState(_name);

  const handleNameBlur = () => {
    setName(displayName ?? '');
    setIsEditNameMode(false);
  };

  const handleSaveNameClick = async (e: MouseEvent<SVGElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    toast.promise(
      (async () => {
        const isNamedSaved = await apollo.mutate({
          mutation: gql`
            mutation SetName($id: ScriptId!, $name: String!) {
              saveScriptOptions(id: $id, options: { name: $name })
            }
          `,
          variables: {
            id: scriptId,
            name,
          },
        });
        if (isNamedSaved) {
          setDisplayName(name);
          setName(name)
          setIsEditNameMode(false);
        }
      })(),
      {
        loading: 'Saving name...',
        success: 'New name saved !',
        error: e => {
          console.error('Failed save name', e);
          return 'Failed save name';
        },
      },
    );
  };

  return (
    <>
      {isEditNameMode ? (
        <Input
          className="w-fit h-7"
          autoFocus
          value={name}
          onChange={e => setName(e.target.value)}
          onBlur={handleNameBlur}
        />
      ) : (
        <h4>{displayName}</h4>
      )}
      {isEditNameMode ? (
        <CheckIcon role="button" className="w-4 h-4 text-muted-foreground" onMouseDown={e => handleSaveNameClick(e)} />
      ) : (
        <Edit3Icon role="button" className="w-4 h-4 text-muted-foreground" onClick={() => setIsEditNameMode(true)} />
      )}
    </>
  );
}
