import { useTheme } from '@/components/theme-provider';
import { NetworkBlock, NetworkBlockProps } from './NetworkBlock';
import { Console } from 'console-feed';
import { Children } from '@utils';

export function ErrorBlock({ error, children, ...rest }: { error: any } & NetworkBlockProps & Children) {
  const { theme } = useTheme();

  return (
    <NetworkBlock {...rest}>
      <Console
        logs={[
          {
            id: 'error',
            method: 'error',
            data: Array.isArray(error) ? error : [error],
          },
        ]}
        variant={theme === 'dark' ? 'dark' : 'light'}
      />
      {children}
    </NetworkBlock>
  );
}
