import SyntaxHighlighter from 'react-syntax-highlighter';

import * as themes from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { NetworkBlock, NetworkBlockProps } from './NetworkBlock';
import { Cls } from '@utils';
import { ReactNode, useMemo } from 'react';
import { Console } from 'console-feed';
import { useTheme } from '@/components/theme-provider';

type Methods =
  | 'log'
  | 'debug'
  | 'info'
  | 'warn'
  | 'error'
  | 'table'
  | 'clear'
  | 'time'
  | 'timeEnd'
  | 'count'
  | 'assert';

interface Log {
  id: string;
  // The log method
  method: Methods;
  // The arguments passed to console API
  data: any[];
  actions?: ReactNode;
}
export function JsonBlock({ json, actions, ...rest }: { json: any } & NetworkBlockProps & Cls) {
  const { theme } = useTheme();

  const [logs, toCopy] = useMemo(() => {
    if (!Array.isArray(json)) {
      const ret = JSON.stringify(json, null, 2);
      return [ret, ret];
    }
    const logs: Log[] = [];
    let id = 0;
    const toCopy = [];
    for (const elt of json) {
      if (elt && typeof elt === 'object' && '$log$' in elt) {
        logs.push({
          id: ++id + '',
          method: elt.$log$.level,
          data: elt.$log$.message,
        });
      } else {
        toCopy.push(elt);
        logs.push({
          id: ++id + '',
          method: 'log',
          data: [elt],
        });
      }
    }
    return [logs, JSON.stringify(toCopy, null, 2)];
  }, [json]);

  if (typeof logs === 'string') {
    return (
      <NetworkBlock {...rest} copy={toCopy} actions={actions}>
        <SyntaxHighlighter language="json" style={theme === 'dark' ? themes.tomorrowNight : themes.a11yLight}>
          {logs}
        </SyntaxHighlighter>
      </NetworkBlock>
    );
  } else {
    return (
      <NetworkBlock {...rest} copy={toCopy} actions={actions}>
        <div className="bg-block">
          <Console key={rest.time?.toString()} logs={logs} variant={theme === 'dark' ? 'dark' : 'light'} />
        </div>
      </NetworkBlock>
    );
  }
}
