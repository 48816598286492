import { useTheme } from '@/components/theme-provider';
import Editor from '@monaco-editor/react';

export function EventSigEditor({ value, onChange, readOnly }: { value: string | nil; readOnly?: boolean; onChange: (v: string | nil) => any }) {
  const { theme } = useTheme();

  return (
    <div className="pl-4 event-filter">
      <Editor
        language="csharp"
        options={{
          autoIndent: 'full',
          minimap: { enabled: false },
          fontSize: 12,
          lineHeight: 14,
          fontWeight: 300,
          lineNumbers: 'off',
          readOnly,
          scrollbar: {
            verticalScrollbarSize: 7,
            horizontalScrollbarSize: 7,
          }
        }}
        height="150px"
        value={value || ''}
        onChange={value => onChange(value)}
        theme={theme === 'dark' ? 'vs-dark' : 'light'}
      />
    </div>
  );
}
