import { TabsContent } from '@/components/ui/tabs';
import { ScriptDetailsTab } from '../editor/ScriptDetailsRoot';
import { ScriptBillingGraph } from './ScriptBillingGraph';
import { ScrollArea } from '@/components/ui/scroll-area';

type ScriptBillingProps = {
  shouldDisplayUsd?: boolean;
};

export function ScriptBilling({ shouldDisplayUsd }: ScriptBillingProps) {
  return (
    <TabsContent value={ScriptDetailsTab.Billing} className="flex flex-col gap-6 pt-6 overflow-hidden">
      <ScrollArea>
        <ScriptBillingGraph shouldDisplayUsd={shouldDisplayUsd} />
      </ScrollArea>
    </TabsContent>
  );
}
