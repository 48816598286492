import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { ScriptStatus } from '@gql';
import { CheckCircledIcon } from '@radix-ui/react-icons';
import { AlertOctagonIcon, AlertTriangleIcon, FileEditIcon, PauseCircleIcon } from 'lucide-react';
import { ReactNode } from 'react';

export function ScriptStatusBadge({ status }: { status: ScriptStatus }) {
  return <Badge className={cn('p-2 hover:bg-transparent text-sm leading-[14px] flex items-center gap-2 w-fit h-8', scriptStatusColors[status])}>
    {scriptStatusIcons[status]}
    {scriptStatusNames[status]}
  </Badge>;
}

const scriptStatusNames: Record<ScriptStatus, string> = {
  [ScriptStatus.active]: 'Active',
  [ScriptStatus.alwaysActive]: 'Always active',
  [ScriptStatus.paused]: 'Paused',
  [ScriptStatus.draft]: 'Draft',
  [ScriptStatus.paymentRequired]: 'Payment required',
  [ScriptStatus.waitingPeers]: 'Waiting peers',
};

// TODO: maybe name and add colors to index.css + add hover color
const scriptStatusColors: Record<ScriptStatus, string> = {
  [ScriptStatus.active]: 'bg-[#4CB5921A] text-[#4CB592] hover:bg-[#4CB5921A]',
  [ScriptStatus.alwaysActive]: 'bg-[#4CB5921A] text-[#4CB592] hover:bg-[#4CB5921A]',
  [ScriptStatus.paused]: 'bg-[#E58A0033] text-[#E58A00] hover:bg-[#E58A0033]',
  [ScriptStatus.draft]: 'bg-accent text-foreground hover:bg-accent',
  [ScriptStatus.paymentRequired]: 'bg-[#E147474D] text-[#E14747] hover:bg-[#E147474D]',
  [ScriptStatus.waitingPeers]: 'bg-[#ADADAD52] text-[#A1A1AA] hover:bg-[#ADADAD52]',
};

const scriptStatusIcons: Record<ScriptStatus, ReactNode> = {
  [ScriptStatus.active]: <CheckCircledIcon className="w-4 h-4 text-[#4CB592]" />,
  [ScriptStatus.alwaysActive]: <CheckCircledIcon className="w-4 h-4 text-[#4CB592]" />,
  [ScriptStatus.paused]: <PauseCircleIcon className="w-4 h-4 text-[#E58A00]" />,
  [ScriptStatus.draft]: <FileEditIcon className="w-4 h-4 text-foreground" />,
  [ScriptStatus.paymentRequired]: <AlertTriangleIcon className="w-4 h-4 text-[#E14747]" />,
  [ScriptStatus.waitingPeers]: <AlertOctagonIcon className="w-4 h-4 text-[#A1A1AA]" />,
};
