import { useParams } from 'react-router-dom';
import { ExecErrorsList, HistoryList, WebhookErrorsList } from '../editor/HistoryList';
import { TabBar } from '@/components/ui/tab-bar';
import { useState } from 'react';

export function Messages() {
  const { scriptId } = useParams<{ scriptId: ScriptId }>();
  const [tab, setTab] = useState('history');

  return (
    <div className="flex pt-2.5 h-full">
      <div className="flex flex-col w-full h-full">
        <div className="pt-2 pr-2">
          <TabBar
            activeTabId={tab}
            tabs={[
              { id: 'history', label: ' History' },
              { id: 'exec-errors', label: 'Execution errors' },
              { id: 'webhook-errors', label: 'Webhook errors' },
            ]}
            onChange={setTab}
          />
        </div>
        <div className="bg-secondary p-2 rounded-r-xl rounded-b-xl">
          {tab === 'history' && <HistoryList id={scriptId!} />}
          {tab === 'last-errors' && <ExecErrorsList id={scriptId!} />}
          {tab === 'webhook-errors' && <WebhookErrorsList id={scriptId!} />}
        </div>
      </div>
    </div>
  );
}
