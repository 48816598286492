import { Stats } from './Stats';
import { ComposedChart, Line, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';

type ChartProps = {
  data: Stats[];
};

export function Chart({ data }: ChartProps) {
  return (
    <div className="flex flex-col rounded-xl gap-2.5 bg-secondary w-full">
      <div className="flex py-6 px-4 gap-1.5 items-center w-full">
        <div className="flex-1 text-base leading-4">Messages</div>
        <div className="flex gap-6 p-2.5">
          <div className="flex gap-1.5 items-center">
            <div className="w-2 h-2 bg-white rounded-full" />
            <span className="text-sm font-normal text-muted-foreground">Success</span>
          </div>
          <div className="flex gap-1.5 items-center">
            <div className="w-2 h-2 bg-[#7C3AED80] rounded-full" />
            <span className="text-sm font-normal text-muted-foreground">Errors</span>
          </div>
          <div className="flex gap-1.5 items-center">
            <div className="w-3 h-0.5 bg-[#E58A00]" />
            <span className="text-sm font-normal text-muted-foreground">Emitted</span>
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={data}>
          <XAxis dataKey="date" tickFormatter={showDate} minTickGap={50} textAnchor="end" interval="preserveStartEnd" />
          <YAxis yAxisId="execution" axisLine={false} tickLine={false} />
          <YAxis yAxisId="messages" orientation="right" axisLine={false} tickLine={false} tick={{ fill: '#E58A00' }} />
          <Bar dataKey="success" stackId="execution" yAxisId="execution" fill="#ffffff" radius={[4, 4, 0, 0]} />
          <Bar
            dataKey="errors"
            stackId="execution"
            yAxisId="execution"
            barSize={40}
            fill="#7C3AED80"
            radius={[4, 4, 4, 4]}
          />
          <Line type="linear" dataKey="emitted" yAxisId="messages" stroke="#E58A00" strokeWidth="1" dot={false} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

function showDate(date: string) {
  // forwmat to YYYY-MM-DD
  return new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
}
