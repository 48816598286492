import './index.css';
import { Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements, useLocation, useNavigate } from 'react-router-dom';
import { Scripts } from './routes/scripts/Scripts';
import { NotFound } from './routes/404/404';
import { MenuBar } from './MenuBar';
import { useIsLoggedIn } from './apollo';
import { Login } from './login/Login';
import { AddressLists } from './routes/addresslists/AddressLists';
import { Databases } from './routes/dbs/Databases';
import { Header } from './Header';
import { BillingRoot } from './routes/billing/BillingRoot';
import { useEffect, useState } from 'react';
import { ApiTokens } from './routes/api-tokens/ApiTokens';
import { AccountsList } from './routes/account-settings/AccountsList';
import { Fork } from './routes/scripts/fork/Fork';

function Root() {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const [decodedHash, setDecodedHash] = useState<string>();
  
  const navigateToScript = () => {
    navigate('/scripts', { replace: true });
  }

  useEffect(() => {
    if (/#fork=([A-Za-z0-9+/=]+)/.test(hash)) {
      try {
        const _decodedHash = atob(hash.split('fork=')[1]);
        setDecodedHash(_decodedHash);
        return;
      } catch (e) {
        navigateToScript();
      }
    }
    navigateToScript();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return decodedHash ? <Fork decodedHash={decodedHash} /> : null;
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="/" element={<Root />} />
      <Route path="/scripts/*" element={<Scripts />} />
      <Route path="/billing" element={<BillingRoot />} />
      <Route path="/api-tokens" element={<ApiTokens />} />
      <Route path="/account-settings" element={<AccountsList />} />
      <Route path="/address-lists/*" element={<AddressLists />} />
      <Route path="/dbs/*" element={<Databases />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

function Layout() {
  const isLoggedIn = useIsLoggedIn();

  if (!isLoggedIn) {
    return <Login />;
  }

  return (
    <div className="h-full flex">
      <MenuBar />
      <div className="bg-background px-4 pt-4 flex flex-col w-[calc(100vw-80px)]">
        <Header />
        <div className="h-[calc(100vh-16px-52px)]">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

function App() {
  return <RouterProvider router={router} />;
}

export default App;
