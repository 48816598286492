import { cn } from '@/lib/utils';
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from './dropdown-menu';
import { Input } from './input';

type MultiselectProps = {
  options: string[];
  selected: string[] | nil;
  onChange: (selected: string[]) => void;
  placeholder?: string;
  readOnly?: boolean;
};

export function Multiselect({ onChange, options, selected, placeholder, readOnly }: MultiselectProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={cn(readOnly && 'pointer-events-none')}>
        <Input
          className="h-10 bg-muted border-none rounded-xl capitalize cursor-pointer"
          value={!selected?.length ? '' : selected.join(', ')}
          readOnly
          placeholder={placeholder}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        {options.map(option => (
          <DropdownMenuCheckboxItem
            key={option}
            checked={selected?.includes(option)}
            className="capitalize"
            onCheckedChange={checked =>
              onChange(checked ? [...(selected ?? []), option] : selected?.filter(x => x !== option) ?? [])
            }
          >
            {option}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
