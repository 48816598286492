import { useAuthToken } from "@/apollo";
import { gql } from "@apollo/client";
import { GetCurrentAccountName } from "@gql";
import { Loader } from "@utils";
import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";

const currentAccountNameAtom = atom<string | null>({
  key: 'current-account-name',
  default: null,
});

export function useCurrentAccountName() {
  const token = useAuthToken();
  const [name, setName] = useRecoilState(currentAccountNameAtom);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const currentAccountName = Loader.query<GetCurrentAccountName>(
    gql`
      query GetCurrentAccountName {
        currentAccount {
          name
        }
      }
    `,
    {
      refetchWhenChanges: [token, refreshTrigger],
    }
  ).map(x => x.currentAccount.name);

  useEffect(() => {
    setName(currentAccountName.match.notOk(() => '').ok(name => name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccountName]);

  const refresh = () => {
    setRefreshTrigger(prev => prev + 1);
  }

  return { name, refresh };
}
