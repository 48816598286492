import { Button } from '@/components/ui/button';
import { PlusCircledIcon } from '@radix-ui/react-icons';

type AddConditionsProps = {
  onClick: () => void;
};

export function AddConditions({ onClick }: AddConditionsProps) {
  return (
    <div className="flex p-2">
      <div className="flex rounded-xl items-center justify-center border-dashed border w-[338px]">
        <Button onClick={onClick} variant="secondary" className="flex items-center gap-2">
          <PlusCircledIcon className="w-4 h-4" />
          <span>Add conditions</span>
        </Button>
      </div>
    </div>
  );
}
