import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { Pencil1Icon, PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import { gql, useApolloClient } from '@apollo/client';
import { Loader } from '@utils';
import { useParams } from 'react-router-dom';
import { Separator } from '@/components/ui/separator';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { useState } from 'react';
import { Input } from '@/components/ui/input';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { LoadWebhooks, LoadWebhooksVariables } from '@gql';
import { MoreHorizontalIcon } from 'lucide-react';
import { Skeleton } from '@/components/ui/skeleton';
import { ErrorAlert } from '@/components/ui/alert';
import { toast } from 'sonner';

// TODO: maybe use only one dialog for add and edit
export function Webhooks() {
  const apollo = useApolloClient();
  const { scriptId } = useParams<{ scriptId: ScriptId }>();
  const [urlValue, setUrlValue] = useState('');
  const [previousEditedUrl, setPreviousEditedUrl] = useState('');
  const [isAddUrlModalOpen, setIsAddUrlModalOpen] = useState(false);
  const [isEditUrlModalOpen, setIsEditUrlModalOpen] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const refresh = () => setRefreshTrigger(prev => prev + 1);

  const webhooks = Loader.query<LoadWebhooks>(
    gql`
      query LoadWebhooks($id: ScriptId!) {
        script(id: $id) {
          webhooks {
            url
          }
        }
      }
    `,
    {
      variables: {
        id: scriptId,
      } as LoadWebhooksVariables,
      refetchWhenChanges: [refreshTrigger],
    },
  ).map(result => result.script.webhooks);

  const webhooksUrl = webhooks.match.notOk(() => []).ok(webhooks => webhooks.map(({ url }) => url));

  const handleAddWebookUrl = async () => {
    try {
      const hasBeenAdded = await apollo.mutate({
        mutation: gql`
          mutation AddWebhookUrl($id: ScriptId!, $webhooks: [String!]) {
            saveScriptOptions(id: $id, options: { webhooks: $webhooks })
          }
        `,
        variables: {
          id: scriptId,
          webhooks: [...webhooksUrl, urlValue],
        },
      });
      if (hasBeenAdded) {
        toast.success('Webhook URL added');
        setUrlValue('');
        setIsAddUrlModalOpen(false);
        refresh();
      }
    } catch (e) {
      toast.error('Failed to add webhook url');
    }
  };

  const handleDeleteWebookUrl = async (url: string) => {
    try {
      const hasBeenDeleted = await apollo.mutate({
        mutation: gql`
          mutation DeleteWebhookUrl($id: ScriptId!, $webhooks: [String!]) {
            saveScriptOptions(id: $id, options: { webhooks: $webhooks })
          }
        `,
        variables: {
          id: scriptId,
          webhooks: webhooksUrl.filter((webhook: string) => webhook !== url),
        },
      });
      if (hasBeenDeleted) {
        toast.success('Webhook URL deleted');
        setIsAddUrlModalOpen(false);
        refresh();
      }
    } catch (e) {
      toast.error('Failed to delete webhook url');
    }
  };

  const handleEditWebookUrl = async () => {
    const newWebhooksUrl = [...webhooksUrl];
    const index = newWebhooksUrl.indexOf(previousEditedUrl);
    if (~index) {
      newWebhooksUrl[index] = urlValue;
    }

    try {
      const hasBeenModified = await apollo.mutate({
        mutation: gql`
          mutation EditWebhookUrl($id: ScriptId!, $webhooks: [String!]) {
            saveScriptOptions(id: $id, options: { webhooks: $webhooks })
          }
        `,
        variables: {
          id: scriptId,
          webhooks: newWebhooksUrl,
        },
      });
      if (hasBeenModified) {
        toast.success('Webhook URL modified');
        setUrlValue('');
        setIsEditUrlModalOpen(false);
        refresh();
      }
    } catch (e) {
      toast.error('Failed to edit webhook url');
    }
  };

  const handleEditModalOpenChange = (url: string) => {
    setPreviousEditedUrl(url);
    setUrlValue(url);
    setIsEditUrlModalOpen(true);
  };

  return (
    <>
      <ScrollArea className="flex flex-col gap-px w-fit">
        <span className="text-lg font-medium">Webhooks</span>
        <Separator className="w-[672px] my-6" />
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>URL</TableHead>
              <TableHead className="justify-end flex">
                <Dialog open={isAddUrlModalOpen} onOpenChange={setIsAddUrlModalOpen}>
                  <DialogTrigger asChild>
                    <Button size="icon" variant="secondary">
                      <PlusIcon className="w-4 h-4" />
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>New URL</DialogTitle>
                      <DialogDescription>Add new webhook URL</DialogDescription>
                    </DialogHeader>
                    <div>
                      <Input
                        className="h-10 bg-secondary border-none rounded-xl"
                        value={urlValue}
                        onChange={e => setUrlValue(e.target.value)}
                      />
                    </div>
                    <DialogFooter>
                      <Button disabled={!urlValue} size="lg" type="submit" onClick={() => handleAddWebookUrl()}>
                        Add
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {webhooks.match
              .loadingOrSkipped(() =>
                <div className='flex flex-col gap-2'>
                  {Array(5).fill(null).map((_, i) => (
                    <div key={i} className='h-[52px] flex items-center'>
                      <Skeleton className='h-5 w-full' />
                    </div>
                  ))}
                </div>
              )
              .error((e: Error) => <ErrorAlert name={e.name} message={e.message} />)
              .ok(webhooks =>
                webhooks.map(({ url }) => (
                  <TableRow key={url}>
                    <TableCell className="text-sm">{url}</TableCell>
                    <TableCell className="justify-end flex">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild className="self-center">
                          <Button variant="secondary" size="icon">
                            <MoreHorizontalIcon className="w-4 h-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem className="gap-2.5 w-full" onClick={() => handleEditModalOpenChange(url)}>
                            <Pencil1Icon /> Edit
                          </DropdownMenuItem>
                          <DropdownMenuItem className="gap-2.5" onClick={() => handleDeleteWebookUrl(url)}>
                            <TrashIcon /> Delete
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                )),
              )}
          </TableBody>
        </Table>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
      <Dialog open={isEditUrlModalOpen} onOpenChange={setIsEditUrlModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit URL</DialogTitle>
            <DialogDescription>Edit webook URL</DialogDescription>
          </DialogHeader>
          <div>
            <Input
              className="h-10 bg-secondary border-none rounded-xl"
              value={urlValue}
              onChange={e => setUrlValue(e.target.value)}
            />
          </div>
          <DialogFooter>
            <Button disabled={!urlValue} size="lg" type="submit" onClick={() => handleEditWebookUrl()}>
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
