import { ErrorAlert } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { FormField } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';
import { gql, useApolloClient } from '@apollo/client';
import { LoadScriptNameInfo, LoadScriptNameInfo_script } from '@gql';
import { Loader } from '@utils';
import { SaveIcon } from 'lucide-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

export function Information() {
  const scriptId = useParams().scriptId as ScriptId;

  const scriptDetails = Loader.query<LoadScriptNameInfo>(
    gql`
      query LoadScriptNameInfo($id: ScriptId!) {
        script(id: $id) {
          name
        }
      }
    `,
    {
      variables: { id: scriptId },
    },
  ).map(x => x.script);

  return (
    <ScrollArea className="flex flex-col gap-px w-fit">
      <span className="text-lg font-medium">Information</span>
      <Separator className="w-[672px] my-6" />
      {scriptDetails.match
        .loadingOrSkipped(() => <div>Loading...</div>)
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(script => (
          <Form scriptInfo={script} />
        ))}

      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}

type FormProps = {
  scriptInfo: LoadScriptNameInfo_script;
};

function Form({ scriptInfo }: FormProps) {
  const scriptId = useParams().scriptId as ScriptId;
  const apollo = useApolloClient();
  const [name, setName] = useState(scriptInfo.name ?? '');

  const handleSave = async () => {
    try {
      const isNamedSaved = await apollo.mutate({
        mutation: gql`
          mutation SetNameInfo($id: ScriptId!, $name: String!) {
            saveScriptOptions(id: $id, options: { name: $name })
          }
        `,
        variables: {
          id: scriptId,
          name,
        },
      });
      if (isNamedSaved) {
        toast.success('Name saved');
      }
    } catch (error) {
      toast.error('Failed to save name');
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <FormField label="Name" className="pb-6">
        <Input
          className="h-10 bg-secondary border-none rounded-xl"
          placeholder="Name of your script"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </FormField>
      <FormField label="Description" className="pb-6">
        <Textarea
          placeholder="Some words about it"
          className="bg-secondary border-none rounded-xl min-h-[156px]"
          disabled
        />
      </FormField>
      <FormField label="Related projects" className="pb-6">
        <Input className="h-10 bg-secondary border-none rounded-xl" disabled />
      </FormField>
      <Button size="lg" className="w-fit flex items-center gap-2" onClick={handleSave}>
        <SaveIcon className="w-4 h-4" />
        Save
      </Button>
    </div>
  );
}
