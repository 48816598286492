import { formatDate, notNil, showLargeNumber, showUsd } from '@utils';
import { ReactNode } from 'react';
import { ComposedChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, TooltipProps, Label } from 'recharts';

type ChartProps = {
  data: unknown[];
  items: ChartItem[];
  usd: boolean;
  info?: ReactNode;
  hasLegend?: boolean;
};

export interface ChartItem {
  name: string;
  dataKey: string | ((data: any) => number);
  color: string;
}

export function BillingChart({ data, items, usd, info, hasLegend = true }: ChartProps) {
  return (
    <div className="flex flex-col rounded-xl gap-2.5 bg-secondary w-full">
      <div className="flex py-6 px-4 gap-1.5 items-center w-full">
        <div className="flex-1 text-base leading-4">Billing</div>
        {hasLegend && (
          <div className="flex gap-6 p-2.5 flex-wrap justify-end">
            {items.map(x => (
              <div key={x.name} className="flex gap-1.5 items-center">
                <div className="w-2 h-2 rounded-full aspect-square" style={{ backgroundColor: x.color }} />
                <span className="text-sm font-normal text-muted-foreground whitespace-nowrap">{x.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={data}>
          <XAxis dataKey="date" tickFormatter={showDate} minTickGap={50} textAnchor="end" interval="preserveStartEnd" />
          <YAxis yAxisId="cus" axisLine={false} tickLine={false} tickFormatter={showLargeNumber} />
          {items.map((x, i) => (
            <Bar
              key={x.name}
              dataKey={x.dataKey}
              stackId="cus"
              yAxisId="cus"
              fill={x.color}
              radius={i !== items.length - 1 ? 0 : [4, 4, 0, 0]}
            />
          ))}
          <Tooltip content={<CustomTooltip usd={usd} />} />
        </ComposedChart>
      </ResponsiveContainer>
      {info}
    </div>
  );
}

function showDate(date: string) {
  // forwmat to YYYY-MM-DD
  return new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
}

const CustomTooltip = ({ active, payload, label, usd }: TooltipProps<any, any> & { usd: boolean }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }
  const items = notNil(
    [...payload]
      .sort((a, b) => b.value - a.value)
      .map((entry: any) => {
        const name =
          typeof entry.dataKey === 'string'
            ? entry.dataKey
            : // small hack (cf BillingGrapherScript.tsx)
              entry.dataKey('name');
        const key = label + name;
        if (!entry.value) {
          return null;
        }
        return (
          <p key={key} style={{ color: entry.fill }}>
            {name}: {usd ? showUsd(entry.value) : showLargeNumber(entry.value)}
          </p>
        );
      }),
  );
  if (!items.length) {
    return null;
  }
  return (
    <div
      className="rounded-md bg-popover px-3 py-1.5 text-xs text-primary-foreground animate-in fade-in-0 zoom-in-95"
      style={{}}
    >
      <p>{formatDate(label, 'date')}</p>
      {items}
    </div>
  );
};
