import { gql } from '@apollo/client';
import { Loader } from '@utils';
import { useParams } from 'react-router-dom';
import { LoadScriptBillingGraph } from '@gql';
import { BillingGraph } from '@/routes/billing/BillingGraph';
import { TriangleAlertIcon } from 'lucide-react';

type ScriptBillingGraphProps = {
  shouldDisplayUsd?: boolean;
}

export function ScriptBillingGraph({ shouldDisplayUsd }: ScriptBillingGraphProps) {
  const { scriptId } = useParams<{ scriptId: ScriptId }>();

  const stats = Loader.query<LoadScriptBillingGraph>(
    gql`
      query LoadScriptBillingGraph($id: ScriptId!) {
        script(id: $id) {
          billingGraph
        }
        billingItems {
          id
          name
          category
        }
      }
    `,
    {
      variables: {
        id: scriptId!,
      },
    },
  );

  return (
    <BillingGraph
      data={stats}
      usd={shouldDisplayUsd}
      info={
        <div className="flex items-center justify-center text-center py-2 text-sm leading-[14px] text-muted-foreground gap-2">
          <TriangleAlertIcon className="w-4 h-4 text-[#E58A00]" />
          Costs incurred by address lists and script databases are not specific to a script, thus are not included here.
          Find corresponding costs in your billing settings.
        </div>
      }
    />
  );
}
