import { Maximize2Icon } from 'lucide-react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import * as themes from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Button } from './button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from './dialog';
import { useTheme } from '@/components/theme-provider';
import Editor from '@monaco-editor/react';
import { useState } from 'react';

type MiniPayloadProps = {
  value?: any;
  onChange?: (value: any) => void;
  entryKey?: string;
};

export function MiniPayload({ value = '', entryKey, onChange }: MiniPayloadProps) {
  const { theme } = useTheme();
  const [entryValue, setEntryValue] = useState<string>(value ? JSON.stringify(value, null, 4) : 'null');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSubmit = () => {
    onChange?.(JSON.parse(entryValue));
    setIsDialogOpen(false);
  }

  return (
    <div className='relative'>
      <SyntaxHighlighter
        language="json"
        style={theme === 'dark' ? themes.tomorrowNight : themes.a11yLight} customStyle={{ borderRadius: '8px', paddingLeft: '16px', minHeight: '40px', alignContent: 'center', fontSize: '14px', lineHeight: '20px' }}>
        {value ? JSON.stringify(value) : 'null'}
      </SyntaxHighlighter>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogTrigger asChild>
          <Button size="icon" variant="secondary" className="absolute right-2 top-[50%] translate-y-[-50%]">
            <Maximize2Icon className="w-4 h-4" />
          </Button>
        </DialogTrigger>
        <DialogContent isEnterSubmit={false}>
          <DialogHeader>
            <DialogTitle>Edit value</DialogTitle>
            <DialogDescription className='text-xs'>
              {entryKey}
            </DialogDescription>
          </DialogHeader>
          <Editor
            language="json"
            options={{
              autoIndent: 'full',
              minimap: { enabled: false },
              fontSize: 12,
              lineHeight: 14,
              fontWeight: 300,
              lineNumbers: 'off',
            }}
            height="150px"
            value={entryValue}
            onChange={newValue => setEntryValue(newValue || '')}
            theme={theme === 'dark' ? 'vs-dark' : 'light'}
          />
          <DialogFooter>
            <Button type="submit" onClick={handleSubmit}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>

  );
}
