import { ErrorAlert } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { FormField } from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { Switch } from '@/components/ui/switch';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { gql, useApolloClient } from '@apollo/client';
import { LoadScriptProps } from '@gql';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Loader } from '@utils';
import { SaveIcon } from 'lucide-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

const storeHistoryDelay = [
  { label: '10min', value: 60 * 10 },
  { label: '1h', value: 60 * 60 },
  { label: '3h', value: 60 * 60 * 3 },
  { label: '6h', value: 60 * 60 * 6 },
  { label: '12h', value: 60 * 60 * 12 },
  { label: '24h', value: 60 * 60 * 24 },
  { label: '2 days', value: 60 * 60 * 24 * 2 },
  { label: '5 days', value: 60 * 60 * 24 * 5 },
  { label: '1 week', value: 60 * 60 * 24 * 7 },
  { label: '2 weeks', value: 60 * 60 * 24 * 7 * 2 },
  { label: '1 month', value: 60 * 60 * 24 * 31 },
  { label: '3 months', value: 60 * 60 * 24 * 31 * 3 },
];

export function ScriptHistoryConfig() {
  const { scriptId: id } = useParams<{ scriptId: ScriptId }>();

  const props = Loader.query<LoadScriptProps>(
    gql`
      query LoadScriptProps($id: ScriptId!) {
        script(id: $id) {
          id
          storeHistory
        }
      }
    `,
    {
      variables: { id },
    },
  ).map(x => x.script);

  return (
    <ScrollArea className="flex flex-col gap-px w-fit">
      <span className="text-lg font-medium">History</span>
      <Separator className="w-[672px] my-6" />
      {props.match
        .loadingOrSkipped(() => 
          <div className='flex flex-col gap-5'>
            <div className='flex flex-col gap-2'>
              <Skeleton className='h-5 w-32' />
              <Skeleton className='h-10' />
            </div>
            <Skeleton className='h-10 w-28' />
          </div>
        )
        .error(e => <ErrorAlert name={e.name} message={e.message} />)
        .ok(_props => (
          <Form keepHistory={!!_props.storeHistory} storeDelay={_props.storeHistory} />
        ))}
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}

type FormProps = {
  keepHistory: boolean;
  storeDelay: number | null;
};

function Form({ keepHistory: _keepHistory, storeDelay: _storeDelay }: FormProps) {
  const apollo = useApolloClient();
  const { scriptId: id } = useParams<{ scriptId: ScriptId }>();

  const [keepHistory, setKeepHistory] = useState<boolean>(_keepHistory);
  const [storeDelay, setStoreDelay] = useState<string | null>(_storeDelay ? _storeDelay.toString() : null);

  const handleSaveClick = async () => {
    try {
      await apollo.mutate({
        mutation: gql`
          mutation SetKeepHistoryDelay($id: ScriptId!, $storeHistoryDelay: Int, $storeHistory: Boolean) {
            saveScriptOptions(id: $id, options: { storeHistoryDelay: $storeHistoryDelay, storeHistory: $storeHistory  })
          }
        `,
        variables: {
          id,
          storeHistory: keepHistory,
          storeHistoryDelay: keepHistory ? Number(storeDelay) : null,
        },
      });
      toast.success('Saved successfully');
    } catch (e) {
      toast.error('Failed to change script setting');
    }
  };

  return (
    <div className="flex flex-col gap-8">
      <FormField
        label={
          <div className="flex items-center gap-2">
            <Switch checked={keepHistory} onCheckedChange={setKeepHistory} id="keep-history" />
            <Label htmlFor="keep-history">Keep history</Label>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <InfoCircledIcon />
                </TooltipTrigger>
                <TooltipContent>
                  <p className="whitespace-pre-wrap">
                    {`When history storage is enabled, the messages your script emits will be stored for the given period.

You must enable this to be able to fetch data using polling, or the replay functionality in webhooks.

To be noted:
- Storage costs of the whole chosen period will be billed when your messages are stored.
- Once history is activated, your script will run for each matched transaction, even when no websocket is connected.`}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        }
      >
        <Select onValueChange={setStoreDelay} value={storeDelay || undefined} disabled={!keepHistory}>
          <SelectTrigger className="h-10 bg-muted border-none rounded-md capitalize cursor-pointer">
            <SelectValue placeholder="Store delay" />
          </SelectTrigger>
          <SelectContent>
            {storeHistoryDelay.map(({ label, value }) => (
              <SelectItem key={value} value={value.toString()}>
                {label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </FormField>
      <Button type="submit" size="lg" className="w-fit flex items-center gap-2" onClick={() => handleSaveClick()}>
        <SaveIcon className="w-4 h-4" />
        Save
      </Button>
    </div>
  );
}
