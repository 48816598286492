import { match } from 'ts-pattern';
import { Periods } from './time-utils';
import { useEffect, useState } from 'react';

export function useTimeAgo(eventDate: number | Date | string | nil) {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (!eventDate) {
      setValue('');
      return;
    }
    let timeout: any;
    function update() {
      const durationFromEvent = new Date().getTime() - new Date(eventDate!).getTime();
      setValue(getVal(durationFromEvent));

      const delay = match(durationFromEvent)
        .when(
          d => d < Periods.ONE_MINUTE,
          () => Periods.ONE_SECOND,
        )
        .when(
          d => d < Periods.ONE_HOUR,
          () => Periods.ONE_SECOND * 30,
        )
        .otherwise(() => Periods.ONE_HOUR);
      timeout = setTimeout(() => {
        update();
      }, delay);
    }
    update();
    return () => clearTimeout(timeout);
  }, [eventDate]);

  return value;
}

export function getTimeAgoTranslation(eventDate: number | Date) {
  if (!eventDate) {
    return '';
  }
  const durationFromEvent = new Date().getTime() - new Date(eventDate).getTime();
  return getVal(durationFromEvent);
}

function getVal(durationFromEvent: number) {
  return match(durationFromEvent)
    .when(
      d => d < Periods.ONE_SECOND,
      () => 'Just now',
    )
    .when(
      d => d < Periods.ONE_MINUTE,
      v => `${(v / Periods.ONE_SECOND).toFixed(0)}s ago`,
    )
    .when(
      d => d < Periods.ONE_HOUR,
      v => `${(v / Periods.ONE_MINUTE).toFixed(0)} min ago`,
    )
    .when(
      d => d < Periods.ONE_DAY,
      v => `${(v / Periods.ONE_HOUR).toFixed(0)}h ago`,
    )
    .when(
      d => d < Periods.ONE_DAY * 30,
      v => `${(v / Periods.ONE_DAY).toFixed(0)}d ago`,
    )
    .when(
      d => d < Periods.ONE_YEAR,
      v => `${(v / (Periods.ONE_DAY * 30)).toFixed(0)}M ago`,
    )
    .otherwise(v => `${(v / Periods.ONE_YEAR).toFixed(0)}y ago`);
}
