import { knownEvents } from './EventFilter/EventFilter';

export type Filter = AndFilter[];

export interface AndFilter {
  network?: string[] | nil;
  to?: AddressConstraint | nil;
  from?: AddressConstraint | nil;
  internalReads?: AddressConstraint | nil;
  internalWrites?: AddressConstraint | nil;
  internalAny?: AddressConstraint | nil;
  logs?: LogConstraint | nil;
}

export interface LogConstraint {
  logSig: string;
  emittedBy?: AddressConstraint | nil;
  values?: Record<string, AddressConstraint> | nil;
  anyAddress?: AddressConstraint | nil;
  [key: string]: any;
}

export interface AddressConstraint {
  in?: HexString[] | nil;
  list?: AddressListId | nil;
}

export function isExpensiveFilter(filter: Filter) {
  return !filter.length || filter.some(f => {
    // a filter that has an address constraint will be cheap
    if (f.internalReads || f.internalWrites || f.from || f.to) {
      return false;
    }
    // if no log constraint, will be expensive if it has logs
    if (!f.logs) {
      return true;
    }
    // if the event is not well known, we can consider that it will be sufficiently infrequent
    // to not cost too much
    if (!knownEvents[f.logs.logSig]) {
      return false;
    }
    // if well known, check for address constraints
    return !f.logs.emittedBy && !f.logs.anyAddress && !Object.keys(f.logs.addresses ?? {}).length;
  });
}
