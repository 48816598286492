import { DashboardCard } from '@/components/ui/dashboard-card';
import { gql } from '@apollo/client';
import { Loader } from '@utils';
import { useParams } from 'react-router-dom';
import { Chart } from './Chart';
import { LoadScriptStats } from '@gql';
import { CircleCheckBigIcon, CircleXIcon, SatelliteDishIcon } from 'lucide-react';
import { Skeleton } from '@/components/ui/skeleton';
import { ErrorAlert } from '@/components/ui/alert';

export type Stats = {
  date: string;
  success: number;
  errors: number;
  emitted: number;
};

const parseStats = (stats: Stats[]) => {
  let totalSuccess = 0;
  let totalErrors = 0;
  let totalEmitted = 0;
  for (const { success, errors, emitted } of stats) {
    totalSuccess += success;
    totalErrors += errors;
    totalEmitted += emitted;
  }
  return {
    totalEmitted,
    totalErrors,
    totalSuccess,
    graph: stats,
  };
};

export function Stats() {
  const { scriptId } = useParams<{ scriptId: ScriptId }>();

  const stats = Loader.query<LoadScriptStats>(
    gql`
      query LoadScriptStats($id: ScriptId!) {
        script(id: $id) {
          stats
        }
      }
    `,
    {
      variables: {
        id: scriptId!,
      },
    },
  ).map(x => parseStats(x.script.stats as any));

  return (
    <div className="flex gap-6 h-full">
      {stats.match
        .loadingOrSkipped(() =>
          <>
            <div className="flex flex-col h-[442px] justify-between gap-6">
              <Skeleton className="w-[325px] h-full" />
              <Skeleton className="w-[325px] h-full" />
              <Skeleton className="w-[325px] h-full" />
            </div>
            <Skeleton className="w-full" />
          </>
        )
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(({ totalEmitted, totalErrors, totalSuccess, graph }) => (
          <>
            <div className="flex flex-col h-[442px] justify-between">
              <DashboardCard
                title="Total message"
                value={totalEmitted}
                icon={<SatelliteDishIcon className="w-4 h-4" />}
                sub="Emitted messages"
              />
              <DashboardCard
                title="Total success"
                value={totalSuccess}
                icon={<CircleCheckBigIcon className="w-4 h-4" />}
                sub="Successful executions"
              />
              <DashboardCard
                title="Total errors"
                value={totalErrors}
                icon={<CircleXIcon className="w-4 h-4" />}
                sub="Failed executions"
              />
            </div>
            <Chart data={graph} />
          </>
        ))}
    </div>
  );
}
