import { NavigationItem, NavigationMenu, NavigationMenuList } from './components/ui/navigation-menu';
import {
  MoonIcon,
  SunIcon,
} from '@radix-ui/react-icons';
import { Badge } from './components/ui/badge';
import { Tabs, TabsList, TabsTrigger } from './components/ui/tabs';
import { useTheme } from './components/theme-provider';
import { CircleDollarSignIcon, DatabaseIcon, FileTextIcon, KeyRoundIcon, ScrollTextIcon, WalletIcon } from 'lucide-react';

export function MenuBar() {
  const { setTheme } = useTheme();

  return (
    <div className="flex flex-col justify-between gap-20 items-center flex-shrink-0 bg-secondary py-4 px-1.5 w-20 overflow-auto hide-scrollbars">
      <div className="flex flex-col items-center gap-2">
        <span className="font-bold leading-4">bloomr</span>
        <Badge className="rounded-full">Beta</Badge>
      </div>
      <NavigationMenu orientation="vertical" className="flex flex-col justify-evenly gap-20">
        <NavigationMenuList className="flex-col gap-2">
          <NavigationItem label="Scripts" icon={ScrollTextIcon} link="/scripts" />
          <NavigationItem label="Addresses" icon={WalletIcon} link="/address-lists" />
          <NavigationItem label="Databases" icon={DatabaseIcon} link="/dbs" />
          <NavigationItem label="Api tokens" icon={KeyRoundIcon} link="/api-tokens" />
        </NavigationMenuList>
        <NavigationMenuList className="flex-col gap-2">
          <NavigationItem label="Billing" icon={CircleDollarSignIcon} link="/billing" />
          {/* hidden for now */}
          {/* <NavigationItem label="Settings" icon={SettingsIcon} link="/" /> */}
          <NavigationItem
            isExternal
            label="Docs"
            icon={FileTextIcon}
            link="https://docs.bloomr.stream/"
          />
        </NavigationMenuList>
      </NavigationMenu>
      <Tabs defaultValue="dark">
        <TabsList className="rounded-full">
          <TabsTrigger value="light" className="w-6 h-6 p-0" onClick={() => setTheme('light')}>
            <SunIcon className="w-3 h-3" />
          </TabsTrigger>
          <TabsTrigger value="dark" className="w-6 h-6 p-0" onClick={() => setTheme('dark')}>
            <MoonIcon className="w-3 h-3" />
          </TabsTrigger>
        </TabsList>
      </Tabs>
    </div>
  );
}
