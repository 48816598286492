import { ErrorAlert, InfoAlert } from '@/components/ui/alert';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';
import { TableLoader } from '@/components/ui/skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { gql, useApolloClient } from '@apollo/client';
import { GetApiTokens } from '@gql';
import { ClipboardIcon } from '@radix-ui/react-icons';
import { Loader } from '@utils';
import { EyeIcon, PlusSquare, Trash2Icon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

export function ApiTokens() {
  const [isAddApiTokenModalOpen, setIsAddApiTokenModalOpen] = useState(false);
  const [newApiToken, setNewApiToken] = useState('');
  const apollo = useApolloClient();
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const refresh = () => setRefreshTrigger(prev => prev + 1);
  const [newlyAddedToken, setNewlyAddedToken] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const apiTokens = Loader.query<GetApiTokens>(
    gql`
      query GetApiTokens {
        getApiTokens {
          name
          token
        }
      }
    `,
    {
      refetchWhenChanges: [refreshTrigger],
    },
  ).map(x => x.getApiTokens);

  const addApiToken = async () => {
    try {
      setIsSaving(true);
      const addedToken = await apollo.mutate({
        mutation: gql`
          mutation CreateApiToken($name: String!) {
            createApiToken(name: $name)
          }
        `,
        variables: {
          name: newApiToken,
        },
      });
      setNewlyAddedToken(addedToken.data.createApiToken);
      toast.success('API token added!');
      setNewApiToken('');
      setIsAddApiTokenModalOpen(false);
      setIsSaving(false);
      refresh();
    } catch (e) {
      console.error('failed to add api token', e);
      toast.error('failed to add api token');
    }
  };

  const handleTokenDelete = async (value: string) => {
    try {
      await apollo.mutate({
        mutation: gql`
          mutation DeleteApiToken($token: String!) {
            deleteApiToken(token: $token)
          }
        `,
        variables: {
          token: value,
        },
      });
      toast.success('Token deleted !');
      refresh();
    } catch (e) {
      console.error('Failed to delete token', e);
      toast.error('Failed to delete token');
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex gap-4 pr-2 pl-4 pt-4 pb-8 items-center">
        <div className="flex-1 py-1">
          <h4>API tokens</h4>
        </div>
        <Dialog open={isAddApiTokenModalOpen} onOpenChange={setIsAddApiTokenModalOpen}>
          <DialogTrigger asChild>
            <Button size="lg" className="flex items-center gap-2">
              <PlusSquare className="w-4 h-4" />
              <span>Add API token</span>
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>New API token</DialogTitle>
              <DialogDescription>Create</DialogDescription>
            </DialogHeader>
            <div>
              <Input
                className="h-10 bg-secondary border-none rounded-xl"
                value={newApiToken}
                onChange={e => setNewApiToken(e.target.value)}
              />
            </div>
            <DialogFooter>
              <Button disabled={!newApiToken || isSaving} size="lg" type="submit" onClick={() => addApiToken()}>
                Add
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      {apiTokens.match
        .loadingOrSkipped(() => <TableLoader />)
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(tokens => (
          tokens.length === 0 ? <InfoAlert message="No API token yet" /> :
          <ScrollArea>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead />
                </TableRow>
              </TableHeader>
              <TableBody>
                {tokens.map(({ token, name }) => (
                  <TableRow key={token} data-type="secondary">
                    <TableCell className="font-normal text-base leading-7">{name}</TableCell>
                    <TableCell className="text-right pr-5 space-x-2">
                      <AlertDialog>
                        <AlertDialogTrigger>
                          <Button variant="secondary" size="icon">
                            <Trash2Icon className="w-4 h-4" />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent onClick={e => e.stopPropagation()}>
                          <AlertDialogHeader>
                            <AlertDialogTitle>Delete confirmation</AlertDialogTitle>
                            <AlertDialogDescription>
                              Are you sure you want to delete this token?
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={() => handleTokenDelete(token)}>Confirm</AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                      <DisplayToken token={token} isNew={token === newlyAddedToken} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
        ))}
    </div>
  );
}

type DisplayTokenProps = {
  token: string;
  isNew?: boolean;
};

function DisplayToken({ token, isNew }: DisplayTokenProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (isNew) {
      setIsPopoverOpen(true);
    }
  }, [isNew])

  const onCopyTokenClick = (value: string) => {
    navigator.clipboard.writeText(value);
    toast.info('Copied to clipboard');
  };

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger asChild>
        <Button variant="secondary" size="icon">
          <EyeIcon className="w-4 h-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-fit text-xs font-light flex items-center gap-2 select-none py-1.5">
        {token}
        <Button variant="secondary" size="icon" onClick={() => onCopyTokenClick(token)}>
          <ClipboardIcon />
        </Button>
      </PopoverContent>
    </Popover>
  );
}
