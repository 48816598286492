import { gql } from '@apollo/client';
import { LoadGlobalBillingGraphPerScript } from '@gql';
import { Loader } from '@utils';
import { BillingChart, ChartItem } from './Chart';
import hexToHsl from 'hex-to-hsl';
import { Skeleton } from '@/components/ui/skeleton';
import { ErrorAlert } from '@/components/ui/alert';

const baseColors = [
  hexToHsl('#D99262'),
  hexToHsl('#BED962'),
  hexToHsl('#62D1D9'),
  hexToHsl('#B162D9'),
  hexToHsl('#678284'),
];

export function BillingGraphPerScript({ usd }: { usd: boolean }) {
  const valueKey = usd ? '$' : 'cu';

  const byScript = Loader.query<LoadGlobalBillingGraphPerScript>(
    gql`
      query LoadGlobalBillingGraphPerScript {
        billingGraphPerScript
      }
    `,
  ).map([usd], ({ billingGraphPerScript: { scriptNames, graph } }: any) => {
    const items = Object.entries(scriptNames).map<ChartItem>(([k, name]: any, i) => {
      const [h, s, l] = baseColors[i % baseColors.length];
      const div = Math.floor(i / baseColors.length);
      const color = `hsl(${h}, ${s}%, ${l + 10 * div}%)`;
      const nm = name ? name : '#' + BigInt(k);

      return {
        dataKey: x => {
          // small hack (cf Chart.tsx)
          if (x === 'name') {
            return nm;
          }
          const ret = x.scripts?.[k];
          return ret?.[valueKey] ?? 0;
        },
        name: nm,
        color,
      };
    });
    return {
      items: [
        ...items,
        {
          dataKey: x => {
            if (x === 'name') {
              return 'Other';
            }
            return x.other?.[valueKey] ?? 0;
          },
          name: 'Other',
          color: 'hsl(0, 0%, 50%)',
        } satisfies ChartItem,
      ],
      graph: graph,
    };
  });

  return (
    <div className="flex gap-6 h-full">
      {byScript.match
        .loadingOrSkipped(() =>
          <>
            <div className="flex flex-col h-[442px] justify-between gap-6">
              <Skeleton className="w-[325px] h-full" />
            </div>
            <Skeleton className="w-full" />
          </>
        )
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(({ graph, items }) => (
          <>
            <div className="flex flex-col h-[442px] gap-2 w-[325px] rounded-xl bg-secondary p-2 overflow-hidden">
              {items.map(x => (
                <div key={x.name} className="flex gap-1.5 items-center">
                  <div className="w-2 h-2 rounded-full aspect-square" style={{ backgroundColor: x.color }} />
                  <span className="text-sm font-normal text-muted-foreground whitespace-nowrap">{x.name}</span>
                </div>
              ))}
            </div>
            <BillingChart data={graph} items={items} usd={usd} hasLegend={false} />
          </>
        ))}
    </div>
  );
}
