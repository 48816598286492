import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  from,
  GraphQLRequest,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';
import inspect from 'browser-util-inspect';
import jwtDecode from 'jwt-decode';
import { useMemo } from 'react';
import { atom, useRecoilValue } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { localStorageEffect, notNil } from '@utils';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

export const API_URL: string = import.meta.env.VITE_API_URL!;
export const API_WS_URL: string = API_URL.replace('http', 'ws');

// NOT exported
const locale = atom<string | null>({
  key: 'locale',
  effects: [localStorageEffect('lang')],
  default: 'en-US',
});

// NOT exported
const currentAddressAtom = atom<HexString | nil>({
  key: 'current-address',
  effects: [localStorageEffect('current-address')],
  default: null,
});

// NOT exported
const authTokenAtom = atom<string | null>({
  key: 'token',
  effects: [localStorageEffect('token')],
  default: null,
});

export function setAuthToken(token: string | null) {
  setRecoil(authTokenAtom, coerceToken(token));
}

export function setCurrentAddress(address: HexString | nil) {
  setRecoil(currentAddressAtom, address);
}

export function useIsLoggedIn(): boolean {
  const ret = useRecoilValue(authTokenAtom);
  return useMemo(() => !!coerceToken(ret), [ret]);
}

export function useAuthToken(): string | null {
  const ret = useRecoilValue(authTokenAtom);
  return useMemo(() => coerceToken(ret), [ret]);
}

export function useCurrentAddress(): HexString | nil {
  const ret = useRecoilValue(currentAddressAtom);
  return ret;
}

function coerceToken(token: string | nil): string | null {
  if (!token) {
    return null;
  }
  // check if expired
  const { exp } = jwtDecode<{ exp: number }>(token);
  const currentTime = new Date().getTime() / 1000;
  return currentTime > exp ? null : token;
}

// dont use cache...
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const cache = new InMemoryCache({
  dataIdFromObject: () => null!,
  typePolicies: {
    Query: {
      fields: {
        nft: {
          keyArgs: ['id'],
          merge: true,
        },
        tokens: {
          // random key, to avoid merging
          keyArgs: () => Math.random().toString(),
          merge: false,
        },
        myUser: { merge: true },
        portfolio: { merge: true },
      },
    },
  },
  possibleTypes: {
    // declare interfaces here
  },
});

const httpLink = new HttpLink({
  uri: API_URL,
  // include cookies (ephemeral session for UTs, or load-balancer cookie in a deployed environment)
  // TODO: BLoomr remove when staging is ready
  //credentials: 'include',
});

function queryName({ query, variables }: GraphQLRequest) {
  const name = notNil(query.definitions.map(x => (x.kind === 'OperationDefinition' ? x.name?.value : null)))[0];
  return `query ${name} with args ${inspect(variables)}`;
}

const authLink = setContext(async (op, { headers }) => {
  // return the headers to the context so httpLink can read them
  const ret = {
    headers: {
      ...headers,
      'Accept-Language': getRecoil(locale),
    },
  };

  // get the authentication token from local storage if it exists
  const token = getRecoil(authTokenAtom);
  if (token) {
    ret.headers.Authorization = `Bearer ${token}`;
  }
  return ret;
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
     
    graphQLErrors.map(graphQLError => {
       
      console.error(
         
        `[GraphQL error on ${queryName(operation)}]: Message: ${graphQLError.message}, Location: ${
          graphQLError.locations
        }, Path: ${graphQLError.path}`,
        graphQLError,
      );
      if (graphQLError.extensions?.code === 'UNAUTHENTICATED') {
        setRecoil(authTokenAtom, null);
      }
    });
  }
  if (networkError) {
     
    console.error(`[Network error on ${queryName(operation)}]: `, networkError);
  }
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: API_WS_URL,
    connectionParams: () => ({
      token: getRecoil(authTokenAtom),
    }),
  }),
);

// split function to send queries to the right place
// websockets for subscriptions, http for the rest
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

export const apolloClient = new ApolloClient({
  cache,
  defaultOptions,
  link: from([errorLink as unknown as ApolloLink, authLink, splitLink]),
});
