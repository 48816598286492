import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { CodeEditor } from '../CodeEditor';
import { FilterEditor } from '../filter/FilterEditor';
import { Accordion, AccordionContent, AccordionItem } from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { ScriptState } from '../useScript';
import { Tab, TabBar } from '@/components/ui/tab-bar';
import { PayloadTitle } from '@/components/ui/payload-title';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';
import { EditIcon, Maximize2Icon, Minimize2Icon, RocketIcon, SaveIcon } from 'lucide-react';
import { useBeforeUnload } from '@/hooks/useBeforeUnload';
import { useBlocker } from 'react-router-dom';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '@/components/ui/alert-dialog';
import { useWindowSize } from '@/hooks/useWindowSize';
import { Badge } from '@/components/ui/badge';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

export function ScriptEditor({
  script: { onSave, onDeploy, isExpensiveFilter, isDeployed, hasDraft, hasChanged, draft, deployed, startEdit, setCode, setFilter },
  isDraftChanged,
  onFullScreenClick,
  isFullscreen,
}: {
  script: ScriptState;
  isDraftChanged?: (isDraft: boolean) => void;
  onFullScreenClick?: VoidFunction;
  isFullscreen?: boolean;
}) {
  const [currentContent, setcurrentContent] = useState<'script' | 'filters'>('script');
  const [tab, setTab] = useState('deployed');
  const [hasTypeError, setHasTypeError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useBeforeUnload(!hasChanged);
  const blocker = useBlocker(hasChanged);

  const accordionContainerRef = useRef<HTMLDivElement>(null);
  const [accordionContentHeight, setAccordionContentHeight] = useState(0);
  const { height: windowHeight } = useWindowSize();

  const filtersCount = (tab === 'draft' ? draft.filter.length : deployed?.filter?.length) ?? 0;

  useLayoutEffect(() => {
    if (accordionContainerRef && accordionContainerRef?.current) {
      const { top } = accordionContainerRef.current.getBoundingClientRect();
      setAccordionContentHeight(windowHeight - top - (isFullscreen ? 110 : 130));
    }
  }, [isFullscreen, windowHeight]);

  const tabs = useMemo<Tab[]>(() => {
    const ret: Tab[] = [];
    ret.push({ id: 'deployed', label: 'Deployed Script' });
    if (hasDraft) {
      ret.push({
        id: 'draft',
        label: 'Draft' + (hasChanged ? '*' : ''),
      });
    }
    return ret;
  }, [hasDraft, hasChanged, isDeployed]);

  useEffect(() => {
    isDraftChanged?.(tab === 'draft');
  }, [tab]);

  useEffect(() => {
    if (isDeployed && !hasDraft) {
      setTab('deployed');
    } else {
      setTab('draft');
    }
  }, [isDeployed]);

  const handleSaveAndNavigate = async () => {
    const successfullySave = await onSave();
    if (successfullySave) {
      blocker.proceed?.();
    } else {
      blocker.reset?.();
    }
  }

  const handleSaveDraftClick = async () => {
    setIsLoading(true);
    await onSave();
    setIsLoading(false);
  }

  const handleDeployScript = async () => {
    setIsLoading(true);
    await onDeploy();
    setIsLoading(false);
  }

  // TODO: tabar + accordion container will become tabulation payload
  return (
    <>
      <RouteChangeAlert blocker={blocker} onSave={handleSaveAndNavigate} onDiscard={() => blocker.proceed?.()} onCancel={() => blocker.reset?.()} />
      <div className="flex flex-col h-full">
        <div className="flex w-full items-center pt-2 pr-2">
          <TabBar
            activeTabId={tab}
            tabs={tabs}
            onChange={setTab}
          />
          {hasChanged ? (
            <SaveButton isFilterExpensive={isExpensiveFilter} onSave={handleSaveDraftClick} isDisabled={hasTypeError || isLoading} />
          ) : hasDraft && tab === 'draft' ? (
            <DeployButton isFilterExpensive={isExpensiveFilter} onDeploy={handleDeployScript} isDeployed={isDeployed} isDisabled={hasTypeError || isLoading} />
          ) : (
            <Button
              className="flex items-center gap-2"
              onClick={() => {
                startEdit();
                setTab('draft');
              }}
            >
              <EditIcon className="w-4 h-4" />
              Edit
            </Button>
          )}
        </div>
        <div className="h-full w-full rounded-b-xl rounded-r-xl bg-secondary flex p-2">
          <Accordion
            ref={accordionContainerRef}
            type="single"
            value={currentContent}
            className="h-full w-full rounded-xl bg-secondary"
          >
            <AccordionItem value="script" className="border-b-0">
              <PayloadTitle
                title="Script"
                className="rounded-t-xl border-b"
                actions={
                  <>
                    <TooltipProvider delayDuration={100}>
                      <Tooltip>
                        <TooltipTrigger>
                          <Button size="icon" variant="secondary" onClick={onFullScreenClick}>
                            {isFullscreen ? <Minimize2Icon className="w-4 h-4" /> : <Maximize2Icon className="w-4 h-4" />}
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          {isFullscreen ? 'Exit full screen' : 'Full screen'}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <TooltipProvider delayDuration={100}>
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            size="icon"
                            variant="secondary"
                            onClick={() => setcurrentContent(currentContent === 'script' ? 'filters' : 'script')}
                          >
                            {currentContent === 'script' ? <ChevronUpIcon /> : <ChevronDownIcon />}
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          {currentContent === 'script' ? 'Collapse' : 'Expand'}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </>
                }
              />
              <AccordionContent data-overflow-behavior="visible" className="pb-0">
                <div style={{ height: accordionContentHeight }}>
                  {tab === 'draft' ? (
                    <CodeEditor key="draft" code={draft.code} codeChange={v => setCode(v)} onSave={onSave} onError={() => setHasTypeError(true)} />
                  ) : (
                    <>
                      <CodeEditor key="deployed" readOnly code={deployed?.code ?? ''} />
                    </>
                  )}
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="filters" className="border-b-0">
              <PayloadTitle
                title={
                  <div className='flex gap-2 items-center'>
                    Only execute if
                    {filtersCount > 0 && <Badge className='rounded-full aspect-square'>{filtersCount}</Badge>}
                  </div>}
                className={cn(currentContent === 'filters' ? 'border-b' : 'border-t rounded-b-xl')}
                actions={
                  <>
                    <TooltipProvider delayDuration={100}>
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            size="icon"
                            variant="secondary"
                            onClick={() => setcurrentContent(currentContent === 'filters' ? 'script' : 'filters')}
                          >
                            {currentContent === 'filters' ? <ChevronUpIcon /> : <ChevronDownIcon />}
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          {currentContent !== 'script' ? 'Collapse' : 'Expand'}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </>
                }
              />
              <AccordionContent className="pb-0">
                <ScrollArea className="w-full">
                  <div style={{ height: accordionContentHeight }}>
                    {tab === 'draft'
                      ? <FilterEditor filter={draft.filter} filterChange={setFilter} />
                      : <FilterEditor filter={deployed?.filter ?? []} readOnly />
                    }
                  </div>
                  <ScrollBar orientation="horizontal" />
                </ScrollArea>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </>

  );
}

type SaveButtonProps = {
  isFilterExpensive: boolean | undefined;
  onSave: VoidFunction;
  isDisabled?: boolean;
}

function SaveButton({ isFilterExpensive, onSave, isDisabled }: SaveButtonProps) {
  return (
    <>
      {isFilterExpensive
        ? <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button disabled={isDisabled} className="flex items-center gap-2"><SaveIcon className="w-4 h-4" />Save draft</Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Expensive filter</AlertDialogTitle>
              <AlertDialogDescription>
                The filter you have chosen seems not to be very specific. It will likely catch a lot of transactions, and thus may be cost ineficient to run. Are you sure you want to save it?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={onSave}>Confirm</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        : <Button disabled={isDisabled} onClick={onSave} className="flex items-center gap-2"><SaveIcon className="w-4 h-4" />Save draft</Button>}
    </>
  )
}

type DeployButtonProps = {
  isFilterExpensive: boolean | undefined;
  onDeploy: VoidFunction;
  isDeployed: boolean;
  isDisabled?: boolean;
}

function DeployButton({ isFilterExpensive, onDeploy, isDeployed, isDisabled }: DeployButtonProps) {
  const [isDeployConfirmationOpen, setIsDeployConfirmationOpen] = useState(false);
  return (
    <>
      <AlertDialog open={isDeployConfirmationOpen} onOpenChange={setIsDeployConfirmationOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Deploy script</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to deploy this script?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={onDeploy}>Confirm</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {isFilterExpensive
        ? <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button disabled={isDeployed || isDisabled} className="flex items-center gap-2">
              <RocketIcon className="w-4 h-4" />
              Deploy
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Expensive filter</AlertDialogTitle>
              <AlertDialogDescription>
                The filter you have chosen seems not to be very specific. It will likely catch a lot of transactions, and thus may be cost ineficient to run. Are you sure you want to save it?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={() => setIsDeployConfirmationOpen(true)}>Confirm</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        : <Button onClick={() => setIsDeployConfirmationOpen(true)} disabled={isDeployed || isDisabled} className="flex items-center gap-2">
          <RocketIcon className="w-4 h-4" />
          Deploy
        </Button>}
    </>
  )
}

type RouteChangeAlertProps = {
  blocker: ReturnType<typeof useBlocker>;
  onSave: VoidFunction;
  onCancel: VoidFunction;
  onDiscard: VoidFunction;
}

function RouteChangeAlert({ blocker, onSave, onCancel, onDiscard }: RouteChangeAlertProps) {

  return (
    <AlertDialog open={blocker.state === 'blocked'}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Save changes</AlertDialogTitle>
          <AlertDialogDescription>
            You have unsaved changes. Do you want to save and leave this page?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>Cancel</AlertDialogCancel>
          <AlertDialogCancel onClick={onDiscard}>Discard & leave</AlertDialogCancel>
          <AlertDialogAction onClick={onSave}>Save & leave</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
