import { useTheme } from '@/components/theme-provider';
import { Skeleton } from '@/components/ui/skeleton';
import { useNetworkStatus } from '@/hooks/useNetworkStatus';
import { gql } from '@apollo/client';
import { GetScriptTypes, GetTsTypes } from '@gql';
import { Editor } from '@monaco-editor/react';
import { Loader } from '@utils';
import { useEffect, useRef, useState } from 'react';

const DEFAULT_CODE = `// See our documentation: https://docs.bloomr.stream

onTx(tx => {
    // emit messages to be sent to your servers here:
    // emit(...)
})`

export function CodeEditor({ code, codeChange, onSave, readOnly, onError }: { code: string; readOnly?: boolean; codeChange?: (value: string) => void, onSave?: () => void, onError?: VoidFunction }) {
  const [monaco, setMonaco] = useState<any>(null);
  const { theme } = useTheme();
  const { isOnline } = useNetworkStatus();

  // "onSave" callback changes, but we're refering to it in a callback that that is only registered once
  //  => use a ref to keep the latest value
  const onSaveRef = useRef(onSave);
  useEffect(() => {
    onSaveRef.current = onSave;
  }, [onSave]);

  const tsTypes = Loader.query<GetTsTypes>(
    gql`
      query GetTsTypes {
        tsTypes
      }
    `,
    {
      refetchWhenChanges: [isOnline],
    }
  ).map(x => x.tsTypes);

  // extract all possible types that has to be generated
  // and trigger the extraction each time it changes
  const scriptTypes = Loader.useWrap(code ?? '')
    .map(c => {
      const re = /(abi|solidity)<\w+>`[^`]*`/gm;
      // match all the `abi` or `solidity` strings:
      let match;
      const matches = [];
      while ((match = re.exec(c)) !== null) {
        matches.push(match[0]);
      }
      return matches.join('\n');
    })
    .debounce(1500, true)
    .query<GetScriptTypes>(
      [isOnline],
      gql`
        query GetScriptTypes($code: String!) {
          scriptTypes(forCode: $code)
        }
      `,
      x => ({ code: x }),
    )
    .map(x => x.scriptTypes);

  const typesLoader = Loader.array([tsTypes, scriptTypes] as const);

  typesLoader.onError(() => onError?.());

  typesLoader
    .map([monaco], v => (monaco ? v : Loader.skipped))
    .onOk([monaco], ([ts, script]) => {
      if (!monaco) {
        return;
      }
      monaco.languages.typescript.typescriptDefaults.setExtraLibs([
        {
          content: ts,
          filePath: 'bloomr.d.ts',
        },
        {
          content: script,
          filePath: 'script_queries.d.ts',
        },
      ]);
    });

  const editor = (
    <Editor
      options={{
        autoIndent: 'full',
        minimap: { enabled: false },
        readOnly,
        fontSize: 14,
        lineHeight: 20,
        fontWeight: 400,
        scrollbar: {
          verticalScrollbarSize: 7,
          horizontalScrollbarSize: 7,
        }
      }}
      theme={theme === 'dark' ? 'vs-dark' : 'light'}
      defaultLanguage="typescript"
      defaultValue={code || DEFAULT_CODE}
      loading={<Loading />}
      beforeMount={monaco => {
        setMonaco(monaco);

        monaco.editor.addCommand({
          id: 'saveScript',
          run: () => {
            onSaveRef.current?.();
          },
        })

        monaco.editor.addKeybindingRules([{
          keybinding: monaco.KeyCode.KeyS | monaco.KeyMod.CtrlCmd,
          command: 'saveScript',
        }, {
          // just an additional keybinding for olivier that has a weird keyboard layout
          //   ctrl+B to trigger autocomplete
          keybinding: monaco.KeyCode.KeyB | monaco.KeyMod.CtrlCmd,
          command: 'editor.action.triggerSuggest',
        }])
        monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
          target: monaco.languages.typescript.ScriptTarget.ES2020,
          allowNonTsExtensions: true,
          strict: true,
          noImplicitAny: true,
          lib: ['es2020'], // Only include ES2020 library
        });
        // monaco.languages.typescript.typescriptDefaults.addExtraLib(types);
      }}
      onChange={v => codeChange?.(v || '')}
    />
  )

  return typesLoader
    .noFlickering()
    .match.loadingOrSkipped(() =>
      <Loading />
    )
    .error(() => isOnline ? <div className="h-full w-full">Error</div> : editor)
    .ok(types => editor);
}

function Loading() {
  return (
    <div className="pl-12 w-full h-full">
      <div className="flex flex-col gap-2 pt-4">
        <Skeleton className="h-2 w-10" />
        <Skeleton className="ml-8 h-2 w-20" />
        <Skeleton className="ml-8 h-2 w-24" />
        <Skeleton className="ml-8 h-2 w-20" />
        <Skeleton className="ml-8 h-2 w-16" />
        <Skeleton className="ml-8 h-2 w-10" />
        <Skeleton className="ml-16 h-2 w-10" />
        <Skeleton className="ml-16 h-2 w-16" />
        <Skeleton className="ml-16 h-2 w-14" />
        <Skeleton className="ml-8 h-2 w-4" />
        <Skeleton className="ml-8 h-2 w-28" />
        <Skeleton className="ml-8 h-2 w-20" />
        <Skeleton className="h-2 w-4" />
      </div>
    </div>
  )
}