import { Line, LineChart, ResponsiveContainer } from "recharts";

type ChartSummaryProps = {
  chart: unknown[];
}

export function ChartSummary({ chart }: ChartSummaryProps) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={chart} className="!cursor-pointer">
        <Line type="monotone" dataKey="req" key="t" stroke="var(--foreground)" strokeWidth="2" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  )
}