export const WEBSOCKET_SAMPLE_WITH_SDK = `
import { messagesWebsocket } from '@bloomr/sdk';

messagesWebsocket({
    apiKey: '<your bloomr api key>',
    scriptId: '<script id>',
    processMessage: msg => console.log('received message', msg),
    onError: e => console.error('connection error', e),
}).connect();
`

export const WEBSOCKET_SAMPLE_WITHOUT_SDK = `
import { io } from 'socket.io-client';

const socket = io('https://api.bloomr.stream', {
  query: {
    apiKey: '<your bloomr api key>',
    scriptId: '<script id>',
  },
});

socket.on('message', msg => console.log('received message', msg));
socket.on('error', e => console.error('connection error', e));
socket.connect();
`

export const POLLING_SAMPLE = `
import { exhaustivePoller } from '@bloomr/sdk';

// fill these:
const getLastMessageFromDb = () => new Date();
const saveLastMessageToDb = (message: string) => {};

const poller = exhaustivePoller<ScriptMessageShape>({
    apiKey: '<your bloomr api key>',
    scriptId: '<script id>',
    loadCursor: getLastMessageFromDb,
    saveCursor: saveLastMessageToDb,
    processMessage: async msg => console.log('received message', msg),
    onBloomrError: e => console.warn('Bloomr seems to be down', e),
    onProcessError: e => {
        // 3 options here:
        //   - just log an error  => polling will continue to the next message
        //   - throw an error => polling will retry processing later
        //   - call poller.stop() => stop polling
    },
});
poller.start();
`