import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ScriptDetailsCode } from './ScriptDetailsCode';
import { Activity } from '../activity/Activity';
import { ScriptDetailsHeader } from '../ScriptDetailsHeader';
import { ScriptBilling } from '../billing/ScriptBilling';
import { ScriptDetails } from '../details/ScriptDetails';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';

export enum ScriptDetailsTab {
  Activity = 'activity',
  Billing = 'billing',
  Code = 'code',
  Details = 'details',
}

const tabsTriggerClassName =
  'data-[state=active]:bg-transparent border-b-2 border-transparent data-[state=active]:border-b-2 data-[state=active]:border-foreground rounded-none p-2.5 text-base leading-7 font-normal';

export function ScriptDetailsRoot() {
  const [searchParams] = useSearchParams();
  const navigateTo = useNavigate();
  const [shouldDisplayUsd, setShouldDisplayUsd] = useState(true);
  const currentTab = searchParams.get('tab') || ScriptDetailsTab.Activity;

  return (
    <Tabs value={currentTab as ScriptDetailsTab} onValueChange={(value) => navigateTo(`?tab=${value}`)} className="h-full flex flex-col">
      <div className="flex flex-col">
        <div className="py-1 gap-[18px] flex items-center">
          <ScriptDetailsHeader />
          {currentTab === ScriptDetailsTab.Billing && <div className="flex items-center gap-2">
            <Switch checked={shouldDisplayUsd} onCheckedChange={setShouldDisplayUsd} id="prefer-usd" />
            <Label htmlFor="prefer-usd">Estimated $ value</Label>
          </div>}
        </div>
        <TabsList className="bg-transparent w-full rounded-none p-0 h-auto justify-start border-b">
          <TabsTrigger className={tabsTriggerClassName} value={ScriptDetailsTab.Activity}>
            Activity
          </TabsTrigger>
          <TabsTrigger className={tabsTriggerClassName} value={ScriptDetailsTab.Billing}>
            Billing
          </TabsTrigger>
          <TabsTrigger className={tabsTriggerClassName} value={ScriptDetailsTab.Code}>
            Code
          </TabsTrigger>
          <TabsTrigger className={tabsTriggerClassName} value={ScriptDetailsTab.Details}>
            Details
          </TabsTrigger>
        </TabsList>
      </div>
      <Activity />
      <ScriptBilling shouldDisplayUsd={shouldDisplayUsd} />
      <ScriptDetailsCode />
      <ScriptDetails />
    </Tabs>
  );
}
