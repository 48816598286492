import { ReactNode } from "react";

type DashboardCardProps = {
  title: string;
  icon?: ReactNode;
  value: ReactNode;
  sub?: string;
}

export function DashboardCard({ title, icon, value, sub }: DashboardCardProps) {
  return (
    <div className="rounded-xl bg-secondary w-[325px]">
      <div className="flex justify-between items-center px-6 pt-6 pb-2">
        <span className="text-sm font-medium">{title}</span>
        {icon}
      </div>
      <div className="flex flex-col px-6 pb-6">
        <span className="text-2xl font-bold">{value}</span>
        <span className="text-xs text-muted-foreground">{sub}</span>
      </div>
    </div>
  );
}