import { ReactNode } from 'react';
import { Label } from './label';
import { cn } from '@/lib/utils';
import { Trash2Icon } from 'lucide-react';

type FormFieldProps = {
  label: ReactNode;
  children: ReactNode;
  className?: string;
  id?: string;
  sub?: string;
  onClear?: VoidFunction;
  readOnly?: boolean;
};

export function FormField({ children, label, className, id, sub, onClear, readOnly }: FormFieldProps) {
  if (!children) {
    return;
  }

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      <div className='flex justify-between'>
        <Label htmlFor={id} className="w-fit">
          {label}
        </Label>
        {onClear && !readOnly && <div role='button' onClick={onClear} className='flex gap-2 text-muted-foreground text-sm leading-[14px] font-medium items-center'>
          <Trash2Icon className="w-4 h-4" />
          Clear
        </div>}
      </div>
      {children}
      <span className="text-sm font-normal text-muted-foreground">{sub}</span>
    </div>
  );
}
