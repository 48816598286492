import { Button } from '@/components/ui/button';
import { TabsContent } from '@/components/ui/tabs';
import { useState } from 'react';
import { ScriptDetailsTab } from '../editor/ScriptDetailsRoot';
import { Information } from './Information';
import { Webhooks } from '../webhooks/Webhooks';
import { ScriptHistoryConfig } from '../editor/ScriptHistoryConfig';
import { ScriptConfirmationLevelConfig } from '../editor/ScriptConfirmationLevelConfig';

const contentTitles = [
  // hidden for now
  // 'information',
  'history',
  'webhooks',
  // 'confirmation level',
];

export function ScriptDetails() {
  const [activeContent, setActiveContent] = useState<string>('history');

  return (
    <TabsContent value={ScriptDetailsTab.Details} className="flex gap-[118px] pt-6 overflow-clip">
      <div className="flex flex-col gap-1">
        {contentTitles.map(title => (
          <Button
            key={title}
            className="w-[250px] justify-start px-4 capitalize"
            size="lg"
            onClick={() => setActiveContent(title)}
            variant={activeContent === title.toLowerCase() ? 'secondary' : 'ghost'}
          >{title}</Button>
        ))}
      </div>
      {activeContent === 'information' && <Information />}
      {activeContent === 'history' && <ScriptHistoryConfig />}
      {activeContent === 'webhooks' && <Webhooks />}
      {/* {activeContent === 'confirmation level' && <ScriptConfirmationLevelConfig />} */}
    </TabsContent>
  );
}
