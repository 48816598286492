import { useEffect, useMemo, useState } from 'react';
import { AddressConstraint } from './filterdef';
import { gql } from '@apollo/client';
import { Loader, isValidAddress, shortenAddress } from '@utils';
import { GetAddressListsForConstraint } from '@gql';
import { Input } from '@/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Label } from '@/components/ui/label';
import { CirclePlusIcon, Trash2Icon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Skeleton } from '@/components/ui/skeleton';
import { ErrorAlert } from '@/components/ui/alert';

export function AddressConstraintEdit({
  constraint,
  onChange,
  readOnly,
}: {
  constraint: AddressConstraint | nil;
  onChange: (c: AddressConstraint | nil) => void;
  readOnly?: boolean;
}) {
  const [addressInputValue, setAddressInputValue] = useState('');
  const [addressInputErrorMessage, setAddressInputErrorMessage] = useState<string | undefined>(undefined);

  const [addresses, setAddresses] = useState<HexString[] | nil>(constraint?.in);
  const [selectedAddressesList, setSelectedAddressesList] = useState<AddressListId | undefined>(constraint?.list ?? undefined);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (!constraint) {
      setAddresses(undefined);
      setSelectedAddressesList(undefined);
    }
  }, [constraint]);

  const displayedValue = useMemo(() =>
    constraint
    ? constraint?.list
      ? `List "${constraint?.list}"`
      : constraint?.in?.map(address => shortenAddress(address)).join(', ') || ''
    : '', [constraint]);

  const addressesLists = Loader.query<GetAddressListsForConstraint>(
    gql`
      query GetAddressListsForConstraint {
        addressLists {
          name
          count
        }
      }
    `,
  ).map(x => x.addressLists);

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      if (selectedAddressesList && !addressInputValue) {
        onChange({ list: selectedAddressesList });
        setIsPopoverOpen(false);
        setAddressInputValue('');
        setAddressInputErrorMessage(undefined);
      } else {
        if (addressInputValue) {
          let isValid = true;
          if (!isValidAddress(addressInputValue)) {
            setAddressInputErrorMessage('Invalid address');
            isValid = false;
          }
          if (addresses?.includes(addressInputValue as HexString)) {
            setAddressInputErrorMessage('Address already in list');
            isValid = false;
          }
          if (isValid) {
            const _addresses = [...(addresses ?? []), addressInputValue as HexString];
            onChange({ in: _addresses });
            setAddresses(_addresses);

            setSelectedAddressesList(undefined);
            setAddressInputValue('');
            setAddressInputErrorMessage(undefined);

            setIsPopoverOpen(false);
          } else {
            setIsPopoverOpen(true);
          }
        } else {
          onChange(addresses ? { in: addresses } : undefined);
          setIsPopoverOpen(false);
          setAddressInputValue('');
          setAddressInputErrorMessage(undefined);
        }
      }
    } else {
      setIsPopoverOpen(isOpen);
    }
  };

  const handleAddAddress = () => {
    if (!isValidAddress(addressInputValue)) {
      setAddressInputErrorMessage('Invalid address');
      return;
    }
    if (addresses?.includes(addressInputValue as HexString)) {
      setAddressInputErrorMessage('Address already in list');
      return;
    }
    setAddressInputErrorMessage('');
    setAddressInputValue('');
    setSelectedAddressesList(undefined);
    setAddresses([...(addresses ?? []), addressInputValue as HexString]);
  };

  const handleListChange = (value: string) => {
    setAddressInputValue('');
    setAddresses(undefined);
    setAddressInputErrorMessage('');
    setSelectedAddressesList(value as AddressListId);
  };

  const handleAddressDelete = (addressToDelete: HexString) => {
    setAddresses(addresses?.filter(address => address !== addressToDelete));
  }

  const handleAddressInputChange = (value: string) => {
    if (value) {
      if (!isValidAddress(value)) {
        setAddressInputErrorMessage('Invalid address');
      } else {
        setAddressInputErrorMessage('');
      }
    } else {
      setAddressInputErrorMessage('');
    }
    setAddressInputValue(value);
  }

  return (
    <Popover open={isPopoverOpen} onOpenChange={handleOpenChange}>
      <PopoverTrigger className={cn(readOnly && 'pointer-events-none')}>
        <Input
          className="h-10 bg-muted border-none rounded-xl capitalize cursor-pointer"
          placeholder="Any address"
          readOnly
          value={displayedValue}
        />
      </PopoverTrigger>
      <PopoverContent className="flex flex-col px-2 rounded-xl w-fit min-w-[320px] border overflow-hidden">
        <div className="p-2 text-xs leading-5 text-muted-foreground">Insert new address</div>
        <div className='flex flex-col gap-2'>
          <div className='relative'>
            <Input
              className="h-10 border bg-accent border-border pr-10"
              data-status={addressInputErrorMessage ? 'error' : ''}
              placeholder="Add new address"
              value={addressInputValue}
              onChange={e => handleAddressInputChange(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleAddAddress()}
            />
            <CirclePlusIcon onClick={handleAddAddress} role='button' className='w-4 h-4 absolute top-[50%] -translate-y-[50%] right-2' />
          </div>
          {addressInputErrorMessage && <span className='text-xs text-destructive font-light'>{addressInputErrorMessage}</span>}
          <div className='flex flex-col gap-2'>
            {addresses?.map((address) =>
              <div key={address} className='text-xs flex w-full justify-between'>
                {address}
                <Trash2Icon role='button' className='w-4 h-4' onClick={() => handleAddressDelete(address)} />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col pt-2">
          <div className="flex items-center gap-2.5">
            <div className="flex-1 h-px bg-border" />
            <span className="px-2 text-xs leading-5">OR</span>
            <div className="flex-1 h-px bg-border" />
          </div>
          <RadioGroup
            value={selectedAddressesList}
            onValueChange={handleListChange}
            className="flex flex-col p-1"
          >
            {addressesLists.match
              .loadingOrSkipped(() =>
                <div className='flex flex-col gap-2'>
                  {Array.from({ length: 3 }).map((_, i) =>
                    <div key={i} className='flex justify-between'>
                      <Skeleton className='h-2 w-1/2' />
                      <Skeleton className='h-2 w-2' />
                    </div>)}
                </div>
              )
              .error((e) => <ErrorAlert name={e.name} message={e.message} />)
              .ok(lists => (
                <>
                  <div className="px-2 text-xs leading-5 text-muted-foreground">Select an addresses list</div>
                  {lists.map(list => (
                    <div className="p-2" key={list.name}>
                      <div className="flex">
                        <div className="flex flex-1 gap-2">
                          <RadioGroupItem value={list.name} id={list.name} />
                          <Label htmlFor={list.name}>{list.name}</Label>
                        </div>
                        <span className="text-xs text-muted-foreground">{list.count}</span>
                      </div>
                    </div>
                  ))}
                </>
              ))}
          </RadioGroup>
        </div>
      </PopoverContent>
    </Popover>
  );
}
