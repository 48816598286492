import { ErrorAlert, InfoAlert } from '@/components/ui/alert';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { TableLoader } from '@/components/ui/skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { gql, useApolloClient } from '@apollo/client';
import { GetAddressList, GetAddressListVariables } from '@gql';
import { UpdateIcon } from '@radix-ui/react-icons';
import { Loader, isHexString } from '@utils';
import { PlusSquareIcon, Trash2Icon } from 'lucide-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

export function AddressListDetails() {
  const { list } = useParams();
  const [newAddress, setNewAddress] = useState('');
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);

  const [cnt, setcnt] = useState(0);
  const refresh = () => setcnt(x => x + 1);
  const apollo = useApolloClient();

  const ll = Loader.query<GetAddressList>(
    gql`
      query GetAddressList($list: String!) {
        addressList(name: $list) {
          name
          list
          count
        }
      }
    `,
    {
      variables: {
        list: list ?? '',
      } satisfies GetAddressListVariables,
      refetchWhenChanges: [cnt],
    },
  ).map(x => x.addressList);

  const addAddress = async () => {
    if (!isHexString(newAddress)) {
      toast.error('Expecting hex sting, prefixed with 0x');
      return;
    }
    if (newAddress.length !== 42 && newAddress.length !== 66) {
      toast.error('Expecting either an address (42 chars), or a hash (66 chars)');
      return;
    }

    try {
      await apollo.mutate({
        mutation: gql`
          mutation AddAddressListElt($list: String!, $elt: HexString!) {
            addressListAdd(name: $list, items: [$elt])
          }
        `,
        variables: {
          list,
          elt: newAddress,
        },
      });
      refresh();
      toast.success('Address list element added !');
      setNewAddress('');
      setIsAddAddressModalOpen(false);
    } catch (e) {
      console.error('failed to add address list element', e);
      toast.error('Failed to add address list element');
    }
  };

  const handleAddressDelete = async (value: string, list: string) => {
    try {
      await apollo.mutate({
        mutation: gql`
          mutation DeleteAddressListElt($list: String!, $elt: HexString!) {
            addressListRemove(name: $list, items: [$elt])
          }
        `,
        variables: {
          list,
          elt: value,
        },
      });
      toast.success('Address list element deleted !');
      refresh();
    } catch (e) {
      console.error('failed to delete address list element', e);
      toast.error('Failed to delete address list element');
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex gap-4 pr-2 pl-4 pt-4 pb-8 items-center">
        <div className="flex-1 py-1">
          <h4>Addresses</h4>
        </div>
        <div>
          <Button size="lg" variant="secondary" onClick={refresh} className="flex items-center gap-2">
            <UpdateIcon className="w-4 h-4" />
            Refresh
          </Button>
        </div>
        <Dialog open={isAddAddressModalOpen} onOpenChange={setIsAddAddressModalOpen}>
          <DialogTrigger asChild>
            <Button size="lg" className="flex items-center gap-2">
              <PlusSquareIcon className="w-4 h-4" />
              <span>Add address</span>
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>New address</DialogTitle>
              <DialogDescription>Add new address</DialogDescription>
            </DialogHeader>
            <div>
              <Input
                className="h-10 bg-secondary border-none rounded-xl"
                value={newAddress}
                onChange={e => setNewAddress(e.target.value)}
              />
            </div>
            <DialogFooter>
              <Button disabled={!newAddress} size="lg" type="submit" onClick={() => addAddress()}>
                Add
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      {ll.match
        .loadingOrSkipped(() => <TableLoader />)
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(addressList => (
          addressList?.list.length === 0 ? (
            <InfoAlert message='No address yet' />
          ) :
            <ScrollArea>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Address</TableHead>
                    <TableHead>Added date</TableHead>
                    <TableHead />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {addressList?.list.map(address => (
                    <TableRow data-type="secondary" key={address}>
                      <TableCell className="font-normal text-base leading-7">{address}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell className="text-right pr-5">
                        <AlertDialog>
                          <AlertDialogTrigger>
                            <Button variant="secondary" size="icon">
                              <Trash2Icon className="w-4 h-4" />
                            </Button>
                          </AlertDialogTrigger>
                          <AlertDialogContent>
                            <AlertDialogHeader>
                              <AlertDialogTitle>Delete confirmation</AlertDialogTitle>
                              <AlertDialogDescription>
                                Are you sure you want to delete this addrress?
                              </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                              <AlertDialogCancel>Cancel</AlertDialogCancel>
                              <AlertDialogAction onClick={() => handleAddressDelete(address, list!)}>Confirm</AlertDialogAction>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialog>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ScrollArea>
        ))}
    </div>
  );

}
